import clsx from 'clsx';
import styles from './Button.module.css';
import { Link } from 'react-router-dom';

type CommonProps = {
  className?: string;
  variant?: 'primary' | 'secondary' | 'link' | 'outlined';
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  onMouseDown?: (e: React.MouseEvent) => void;
};

type ButtonProps = CommonProps & {
  tag: 'button';
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

type LinkProps = CommonProps & {
  tag: 'a';
  href: string;
  target?: string;
};

export default function Button(props: ButtonProps | LinkProps) {
  const classNames = clsx(
    {
      [styles.buttonPrimary]: props.variant === 'primary' || !props.variant,
      [styles.buttonSecondary]: props.variant === 'secondary',
      [styles.buttonLink]: props.variant === 'link',
      [styles.buttonOutlined]: props.variant === 'outlined',
    },
    props.className
  );

  if (props.tag === 'a') {
    const externalLink = !props.href.startsWith('/');

    if (externalLink) {
      return (
        <a
          className={classNames}
          href={props.href}
          onClick={props.onClick}
          target={props.target}
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      );
    }

    return (
      <Link className={classNames} to={props.href} onClick={props.onClick}>
        {props.children}
      </Link>
    );
  }
  return (
    <button
      className={classNames}
      type={props.type || 'button'}
      disabled={props.disabled}
      onClick={props.onClick}
      onMouseDown={props.onMouseDown}
    >
      {props.children}
    </button>
  );
}
