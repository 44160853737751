import styles from './HeaderLogo.module.css';
import logoSrc from 'assets/svg/logo.svg';

export default function HeaderLogo() {
  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <a href="https://www.threeolives.com">
          <img className={styles.logo} src={logoSrc} alt="Three Olives® Vodka" />
        </a>
      </div>
    </div>
  );
}
