import clsx from 'clsx';
import style from './Title.module.css';
import { firstLetterUppercase } from 'utils/firstLetterUppercase';

export default function Title({
  tag,
  size,
  color,
  children,
  className,
}: {
  tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'span';
  size?: 'xlarge' | 'large' | 'small' | 'medium' | 'xsmall' | 'xsmallthin';
  color?: 'primary' | 'secondary' | 'primarydark';
  children?: React.ReactNode;
  className?: string;
}) {
  const Tag = tag || 'div';
  return (
    <Tag
      className={clsx(
        style.title,
        color && style[`color${firstLetterUppercase(color)}`],
        style[`size${firstLetterUppercase(size || 'medium')}`],
        className
      )}
    >
      {children}
    </Tag>
  );
}
