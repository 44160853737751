import { createPortal } from 'react-dom';
import styles from './Modal.module.css';
import { ReactComponent as CloseIcon } from 'css.gg/icons/svg/close.svg';
import { useEffect } from 'react';

export default function Modal({ children, onClose }: { children: React.ReactNode; onClose?: () => void }) {
  useEffect(() => {
    // Prevent scrolling when modal is open
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return createPortal(
    <div className={styles.modal}>
      <div className={styles.modalInner}>
        <button className={styles.closeButton} onClick={onClose} type="button">
          <CloseIcon />
        </button>
        <div className={styles.content}>{children}</div>
      </div>
    </div>,
    document.body
  );
}
