import React from 'react';
import ErrorMessage from './ErrorMessage';
import HelpText from './HelpText';
import Label from './Label';
import style from './Field.module.css';

export default function Field({
  label,
  error,
  children,
  helperText,
}: {
  label?: React.ReactNode;
  error?: React.ReactNode;
  children: React.ReactNode;
  helperText?: React.ReactNode;
}) {
  return (
    <div className={style.field}>
      {label && <Label>{label}</Label>}
      {children}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {helperText && <HelpText>{helperText}</HelpText>}
    </div>
  );
}
