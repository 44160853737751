import clsx from 'clsx';
import styles from './Input.module.css';
import { ForwardedRef, forwardRef } from 'react';

const Input = (
  {
    name,
    type,
    required,
    placeholder,
    variant,
    pattern,
    className,
  }: {
    name: string;
    type?: string;
    required?: boolean;
    placeholder?: string;
    variant?: 'primary' | 'secondary';
    pattern?: string;
    className?: string;
  },
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <input
      className={clsx(styles.input, className, {
        [styles.primary]: variant === 'primary' || !variant,
        [styles.secondary]: variant === 'secondary',
      })}
      name={name}
      type={type}
      required={required}
      placeholder={placeholder}
      pattern={pattern}
      ref={ref}
    />
  );
};

const InputWithRef = forwardRef(Input);
export default InputWithRef;
export type InputProps = Parameters<typeof Input>[0];
