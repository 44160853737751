import { useCallback, useEffect, useRef } from 'react';

export default function useFormCustomValidations(props: {
  validations: { inputName: string; isValid: (input: HTMLInputElement) => boolean; message: string }[];
}) {
  const listeners = useRef<{ input: HTMLInputElement; validate: () => boolean }[]>([]);
  const validationsRef = useRef(props.validations);
  validationsRef.current = props.validations;

  const validate = useCallback((form: HTMLFormElement) => {
    validationsRef.current.forEach((validation) => {
      const input = form.querySelector(`input[name="${validation.inputName}"]`) as HTMLInputElement;
      listeners.current.forEach((listener) => {
        if (listener.input === input) {
          input.removeEventListener('input', listener.validate);
        }
      });
      if (!_validate(input, validation.isValid, validation.message)) {
        const v = () => _validate(input, validation.isValid, validation.message);
        input.addEventListener('input', v);
        listeners.current.push({ input, validate: v });
      }
    });

    if (!form.checkValidity()) {
      return false;
    }
    return true;
  }, []);

  useEffect(() => {
    const { current } = listeners;
    return () => {
      current.forEach((listener) => {
        listener.input.removeEventListener('input', listener.validate);
      });
    };
  }, []);

  return { validate };
}

const _validate = (input: HTMLInputElement, isValid: (input: HTMLInputElement) => boolean, message: string) => {
  // If the input is empty, we don't validate it.
  if (!input.value) return true;
  // If the input is invalid and the validation message is not the one we want to set, we don't validate it.
  if (!input.validity.valid && input.validationMessage !== message) {
    return true;
  }

  if (!isValid(input)) {
    input.setCustomValidity(message);
    input.reportValidity();
    return false;
  } else {
    input.setCustomValidity('');
    return true;
  }
};
