import useSWR from 'swr';
import { MainPrompt } from 'types/strapi';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';

export default function useMainPrompt() {
  const { data, error } = useSWR<StrapiResponseSuccess<MainPrompt>>([`/main-prompt`], get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = !data && !error;

  return {
    loading,
    mainPrompt: data,
  };
}
