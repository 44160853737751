import qs from 'qs';

export const populate = {
  pageHome: qs.stringify(
    {
      populate: {
        seo: '*',
      },
    },
    {
      encodeValuesOnly: true,
    }
  ),
  pageSubmission: qs.stringify(
    {
      populate: {
        featuredList: {
          populate: {
            image: '*',
          },
        },
        seo: '*',
      },
    },
    {
      encodeValuesOnly: true,
    }
  ),
  pageSubmissionCompleted: qs.stringify(
    {
      populate: {
        seo: '*',
      },
    },
    {
      encodeValuesOnly: true,
    }
  ),
  party: (slug: string) =>
    qs.stringify(
      {
        populate: {
          image: '*',
        },
        filters: {
          slug: slug,
        },
      },
      {
        encodeValuesOnly: true,
      }
    ),
  footer: qs.stringify(
    {
      populate: {
        links: '*',
      },
    },
    {
      encodeValuesOnly: true,
    }
  ),
  flavours: qs.stringify(
    {
      populate: {
        overlayImage: '*',
        cocktails: '*',
      },
      pagination: {
        pageSize: 1000000,
      },
    },
    {
      encodeValuesOnly: true,
    }
  ),
  prompts: qs.stringify(
    {
      pagination: {
        pageSize: 1000000,
      },
    },
    {
      encodeValuesOnly: true,
    }
  ),
};
