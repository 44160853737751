import styles from './Error404.module.css';

export default function Error404() {
  return (
    <main className={styles.errorPage}>
      <div className={styles.errorPageInner}>
        <h1>404 Not Found</h1>
        <a href="/">Go to homepage</a>
      </div>
    </main>
  );
}
