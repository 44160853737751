import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
  EmailIcon,
  FacebookIcon,
  PinterestIcon,
  TwitterIcon,
} from 'react-share';
import styles from './Share.module.css';
import { ReactComponent as IconShare } from 'css.gg/icons/svg/share.svg';
import { ReactComponent as IconInstagram } from 'css.gg/icons/svg/instagram.svg';
import { ReactComponent as IconSms } from 'assets/svg/sms.svg';
import { downloadFile } from 'utils/downloadFile';

export default function Share({ text, url, imageUrl }: { text: string; url: string; imageUrl: string }) {
  const handleWebShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          url,
        });
      } catch (err) {
        console.error(err);
      }
    }
  };

  const handleInstagram = () => {
    downloadFile(imageUrl, 'three-olives-vodka-rager.jpg');
  };

  const iconProps = {
    bgStyle: { fill: 'transparent' },
    iconFillColor: '#fff',
    size: 50,
  };

  return (
    <div className={styles.buttonsWrapper}>
      <div className={styles.buttons}>
        {!!navigator?.share && (
          <button className={styles.customButton} onClick={handleWebShare} type="button">
            <IconShare />
          </button>
        )}
        <button className={styles.customButton} onClick={handleInstagram} type="button">
          <IconInstagram />
        </button>
        <FacebookShareButton url={url} children={<FacebookIcon {...iconProps} />} hashtag="#ThreeOlivesGenerager" />
        <TwitterShareButton
          title={text}
          children={<TwitterIcon {...iconProps} />}
          url={url}
          hashtags={['ThreeOlivesGenerager', 'Contest']}
        />
        <PinterestShareButton
          children={<PinterestIcon {...iconProps} />}
          url={url}
          media={imageUrl}
          description={text}
        />
        <EmailShareButton children={<EmailIcon {...iconProps} />} url={url} subject={text} />
        <a className={styles.customButton} href={`sms:;&body=${text} ${url}`}>
          <IconSms />
        </a>
      </div>
    </div>
  );
}
