import Title from 'components/ui/Title';
import styles from './LoadingText.module.css';
import { useEffect, useRef, useState } from 'react';
import AnimatedText from './AnimatedText';
import Balancer from 'react-wrap-balancer';
import clsx from 'clsx';
import { AnimatedBottle } from './AnimatedBottle';

export default function LoadingText({ withoutBottle }: { withoutBottle?: boolean }) {
  const [text, setText] = useState(loadingTexts[Math.floor(Math.random() * loadingTexts.length)]);
  const textRef = useRef(text);
  textRef.current = text;
  useEffect(() => {
    const interval = setInterval(() => {
      // take random text that is not the current one
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * loadingTexts.length);
      } while (loadingTexts[nextIndex] === textRef.current);
      setText(loadingTexts[nextIndex]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.container}>
      {withoutBottle !== true && <AnimatedBottle />}
      <div className={clsx(styles.textWrapper, !withoutBottle && styles.textWrapperNoBackground)}>
        <Title size="small" className={styles.text} color="secondary">
          <Balancer>
            <AnimatedText>{text}</AnimatedText>
          </Balancer>
        </Title>
      </div>
    </div>
  );
}

const loadingTexts = [
  'Hang tight',
  "We're working on it, BRB",
  'Rager incoming!',
  'Loading. BRB!',
  'Wrapping up your rager',
  'Sit back, relax, your rager is on the way',
  'Sit back, relax, AI is doing all the work',
  "We're cooking up your rager. Almost there.",
  "We're almost there",
  'Your rager is loading, sit back and relax',
  'Rager incoming, hang tight',
  "We're gone raging but AI is working on it, hang tight",
];
