import 'normalize.css';
import { Route, Routes } from 'react-router-dom';
import Error404 from 'pages/Error404';
import ScrollToTop from 'components/shared/ScrollToTop';
import 'App.css';
import PartiesPage from 'pages/Parties';
import ApprovePartiesPage from 'pages/ApproveParties';
import HomePage from 'pages/Home';
import SubmissionPage from 'pages/Submission';
import CompleteSubmissionPage from 'pages/CompleteSubmission';
import PartyPage from 'pages/Party';
import RequireSubmissionData from 'components/shared/RequireSubmissionData';
import PasswordPage from 'pages/Password';
import AgeGate from 'components/shared/AgeGate';
import initGtm from 'utils/gtm';
import CookieNotification from 'components/ui/CookieNotification';
import RulesPage from 'pages/Rules';
import { initRecaptchaEnterprise } from 'utils/recaptchaEnterprise';

// Code execution on app load
initGtm();
initRecaptchaEnterprise();

export default function App() {
  return (
    <>
      <ScrollToTop />
      <AgeGate />
      <CookieNotification />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/submission" element={<SubmissionPage />} />
        <Route
          path="/complete-submission"
          element={
            <RequireSubmissionData>
              <CompleteSubmissionPage />
            </RequireSubmissionData>
          }
        />
        <Route path="/all-ragers" element={<PartiesPage />} />
        <Route path="/all-ragers/:slug" element={<PartyPage />} />
        <Route path="/approve-ragers" element={<ApprovePartiesPage />} />
        <Route path="/approve-ragers/:slug" element={<PartiesPage />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/password" element={<PasswordPage />} />
        <Route path="/rules" element={<RulesPage />} />
      </Routes>
    </>
  );
}
