import styles from './PartyImage.module.css';
import Title from 'components/ui/Title';

export default function PartyImage({
  imageUrl,
  title,
  subtitle,
  overlayImageUrl,
}: {
  imageUrl: string;
  title: string;
  subtitle: string;
  overlayImageUrl: string;
}) {
  title = title.split(':')[0];

  return (
    <div className={styles.partyImageContainer}>
      <img className={styles.partyOverlayImage} src={overlayImageUrl} alt=" " width={896} height={1152} />
      <img className={styles.partyImage} src={imageUrl} alt=" " width={896} height={1152} />
      <div className={styles.partyText}>
        <Title className={styles.partyTitle} size="large" color="secondary">
          {title}
        </Title>
        <Title className={styles.partySubtitle} size="xsmall" color="secondary">
          {subtitle}
        </Title>
      </div>
    </div>
  );
}
