import styles from './LoadingText.module.css';
import { ReactComponent as BottleSvg } from 'assets/svg/bottle222.svg';
import { useEffect } from 'react';
import anime from 'animejs';

export const AnimatedBottle = () => {
  useEffect(() => {
    const bottles = document.querySelectorAll('#bottles #bottle-liquid');
    const a = anime
      .timeline({
        loop: true,
      })
      .add({
        targets: bottles,
        delay: anime.stagger(7000),
        d: [
          {
            value:
              'm20.85 82.2-8.4124 0.15832-12.415-0.14032c0.13013-2.3295-0.17939 0.70349 0.0968-1.8376 6.9121-0.15432 14.288-0.56988 20.497-0.64558z',
            duration: 0,
          },
          {
            value:
              'm20.85 82.2-8.4124 0.15832-12.415-0.14032c0.13013-2.3295-0.29572-79.67-0.01953-82.211 6.9121-0.15432 14.534 0.0757 20.743 0z',
            duration: 7000,
          },
        ],
        easing: 'linear',
      })
      .add({
        targets: bottles,
        opacity: 0,
        duration: 1500,
      });

    return () => {
      a?.pause();
      anime.remove([bottles]);
    };
  }, []);

  return (
    <div id="bottles" className={styles.imagesWrapper}>
      <BottleSvg className={styles.image} />
      <BottleSvg className={styles.image} />
      <BottleSvg className={styles.image} />
    </div>
  );
};
