import { StrapiResponseError, StrapiResponseSuccess } from 'types/strapiResponse';
import getStrapiUrl from './getStrapiUrl';
import { Party } from 'types/strapi';
import { StrapiUser } from 'types/StrapiUser';
// import { getJwt } from './localStorage';

export const apiFetcher = async <T>(url: string, opt?: any): Promise<T> => {
  const res = await fetch(url, opt);
  const data = await res.json();
  if (!res.ok || data.error) {
    // eslint-disable-next-line
    throw data.error as StrapiResponseError;
  }
  return data as T;
};

// const authHeaders = () => {
//   const token = getJwt();
//   return {
//     'Content-Type': 'application/json',
//     Authorization: `Bearer ${token}`,
//   };
// };

const guestHeaders = () => {
  return {
    'Content-Type': 'application/json',
  };
};

export const generateImagesWithCancellation = async (prompt: string, signal?: AbortSignal) => {
  return apiFetcher<{ data: string[] }>(getStrapiUrl(`/parties/generate-images`), {
    method: 'POST',
    headers: guestHeaders(),
    signal,
    body: JSON.stringify({
      data: {
        prompt,
      },
    }),
  });
};

export const generateImages = async (prompt: string) => {
  return apiFetcher<{ data: string[] }>(getStrapiUrl(`/parties/generate-images`), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify({
      data: {
        prompt,
      },
    }),
  });
};

export const generateText = async (prompt: string) => {
  return apiFetcher<{ data: { name: string; description: string } }>(getStrapiUrl(`/parties/generate-text`), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify({
      data: {
        prompt: prompt,
      },
    }),
  });
};

export const validatePrompt = (prompt: string, token: string) => {
  return apiFetcher<StrapiResponseSuccess<Party>>(getStrapiUrl('/parties/validate-prompt'), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify({
      data: { prompt, token },
    }),
  });
};

export const createParty = (formData: FormData) => {
  return apiFetcher<StrapiResponseSuccess<Party>>(getStrapiUrl('/parties'), {
    method: 'POST',
    body: formData,
  });
};

export const loginByProvider = (provider: string, accessToken: string, accessSecret: string | null) => {
  return apiFetcher<StrapiUser>(
    getStrapiUrl(`/auth/${provider}/callback?access_token=${accessToken}&access_secret=${accessSecret || ''}`),
    {
      headers: guestHeaders(),
    }
  );
};

export const login = (props: { identifier: string; password: string }) => {
  return apiFetcher<StrapiUser>(getStrapiUrl('/auth/local'), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify(props),
  });
};

export const resetPassword = (props: { password: string; passwordConfirmation: string; code: string }) => {
  return apiFetcher<StrapiUser>(getStrapiUrl('/auth/reset-password'), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify(props),
  });
};

export const forgotPassword = (props: { email: string }) => {
  return apiFetcher<StrapiUser>(getStrapiUrl('/auth/forgot-password'), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify(props),
  });
};

export const register = (props: { username: string; email: string; password: string }) => {
  return apiFetcher<StrapiUser>(getStrapiUrl('/auth/local/register'), {
    method: 'POST',
    headers: guestHeaders(),
    body: JSON.stringify(props),
  });
};

export const get = <T>(url: string) => {
  return apiFetcher<T>(getStrapiUrl(url), {
    headers: guestHeaders(),
  });
};
