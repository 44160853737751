import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { generateText, validatePrompt } from 'utils/api';
import { serialize } from 'utils/serialize';
import useFlavours from './useFlavours';
import { replacePromptKeys } from 'utils/replacePromptKeys';
import useMainPrompt from './useMainPrompt';
import useSubmissionData from './useSubmissionData';
import { replaceDescription } from 'utils/replaceDescription';
import logError from 'utils/logError';
import { execute } from 'utils/recaptchaEnterprise';

export default function useSubmissionForm() {
  const { mutate: mutateSubmissionData } = useSubmissionData();
  const { mainPrompt } = useMainPrompt();
  const { textPrompt: mainTextPrompt = '' } = { ...mainPrompt?.data?.attributes };
  const { flavours: { data: flavours = [] } = {} } = useFlavours();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [moderationFailed, setModerationFailed] = useState(false);
  const resetSubmission = () => {
    setError('');
    setLoading(false);
    setModerationFailed(false);
  };
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);
    setError('');

    const form = e.target as HTMLFormElement;
    const formData = serialize(form) as {
      ingredient1: string;
      ingredient2: string;
      ingredient3: string;
    };
    const ingredients = [formData.ingredient1, formData.ingredient2, formData.ingredient3].map((c) => c.trim());
    const randomFlavour = flavours[Math.floor(Math.random() * flavours.length)];
    const flavourByKeyword = flavours.find((flavour) => {
      const keywords = flavour.attributes?.keywords?.split(',')?.map((c) => c.trim().toLowerCase()) || [];
      return keywords.some((keyword) => ingredients.map((c) => c.toLowerCase()).includes(keyword));
    });
    const chosenFlavour = flavourByKeyword || randomFlavour;
    const { prompt: flavourTextPrompt = '' } = { ...chosenFlavour?.attributes };
    const cocktails = chosenFlavour.attributes?.cocktails?.data || [];
    const chosenCocktail = cocktails[Math.floor(Math.random() * cocktails.length)];
    const { prompt: cocktailTextPrompt = '' } = { ...chosenCocktail?.attributes };
    const textPrompt = replacePromptKeys(
      { ingredient1: ingredients[0], ingredient2: ingredients[1], ingredient3: ingredients[2] },
      mainTextPrompt + ' ' + (flavourTextPrompt || '') + ' ' + cocktailTextPrompt
    );
    const {
      link: cocktailLink = '',
      name: cocktailName = '',
      recipe: cocktailRecipe = '',
    } = {
      ...chosenCocktail?.attributes,
    };

    let token = '';
    try {
      token = await execute('validate_prompt');
    } catch (err: any) {
      logError('Recaptcha failed token execution');
      setLoading(false);
      setError('Something went wrong');
      return;
    }

    try {
      await validatePrompt(ingredients.join(' '), token);
    } catch (err: any) {
      if (err.message === 'ReCaptcha validation failed') {
        setLoading(false);
        logError('ReCaptcha validation failed');
        setError('Looks like you are a bot. Please refresh the page or try again later.');
        return;
      }
      if (err.message === 'The prompt contains inappropriate content') {
        setLoading(false);
        setError('Ingredients contains inappropriate content');
        setModerationFailed(true);
        return;
      }
      setLoading(false);
      logError(err);
      setError('Something went wrong');
      return;
    }

    try {
      const data = await generateText(textPrompt);
      const generatedText = data.data;
      mutateSubmissionData(
        {
          name: generatedText.name,
          description: `${replaceDescription(
            generatedText.description,
            cocktailName,
            cocktailLink
          )}\n\n**Featured Cocktail:** ${cocktailName}\n\n${cocktailRecipe}`,
          ingredients,
          flavour: chosenFlavour,
          mainPrompt: mainPrompt?.data || null,
          generatedImages: [],
          cocktail: chosenCocktail,
        },
        false
      );
      setLoading(false);
      navigate('/complete-submission');
    } catch (err: any) {
      logError(err);
      setLoading(false);
      setError('Something went wrong');
      return;
    }
  };

  return { error, loading, onSubmit, moderationFailed, resetSubmission, flavours } as const;
}
