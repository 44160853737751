import styles from './CookieNotification.module.css';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import initGtm from 'utils/gtm';

export default function CookieNotification({
  text = 'We use our own cookies and third-party cookies so that we can show you this website and better understand how you use it, with a view to improving the services we offer.',
  privacyPageUrl,
  privacyPageLabel,
  agreeButtonText = 'Ok',
  refuseButtonText = 'No, thank you',
  onAccept,
  onRefuse,
  classNames,
}: {
  text?: string;
  onAccept?: () => any;
  onRefuse?: () => any;
  privacyPageUrl?: string;
  privacyPageLabel?: string;
  agreeButtonText?: string;
  refuseButtonText?: string;
  classNames?: {
    component?: string;
    text?: string;
    buttonsWrapper?: string;
    buttonAgree?: string;
    buttonRefuse?: string;
  };
}) {
  const [open, setOpen] = useState(false);
  const isPrivacyPage = !!privacyPageUrl && !!privacyPageLabel;

  const onAgreeClick = () => {
    accept();
    onAccept?.();
  };

  const onRefuseClick = () => {
    refuse();
    onRefuse?.();
  };

  const accept = () => {
    setOpen(false);
    localStorage.setItem('GDPR:accepted', 'true');
    initGtm();
  };

  const refuse = () => {
    setOpen(false);
    localStorage.setItem('GDPR:accepted', 'false');
  };

  useEffect(() => {
    //Build
    if ((window as any).__PRERENDER_INJECTED) {
      return;
    }

    if (localStorage.getItem('GDPR:accepted') === null) {
      setOpen(true);
    }
  }, []);

  if (!open) {
    return null;
  }

  return (
    <div className={clsx(styles.cookieNotification, classNames?.component)}>
      <div className={clsx(classNames?.text)}>
        {text}
        {isPrivacyPage && (
          <a href={privacyPageUrl} rel="noopener noreferrer" target="_blank">
            {privacyPageLabel}
          </a>
        )}
      </div>
      <div className={clsx(styles.buttonsWrapper, classNames?.buttonsWrapper)}>
        <button type="button" className={clsx(styles.buttonAgree, classNames?.buttonAgree)} onClick={onAgreeClick}>
          {agreeButtonText}
        </button>
        <button type="button" className={clsx(styles.buttonRefuse, classNames?.buttonRefuse)} onClick={onRefuseClick}>
          {refuseButtonText}
        </button>
      </div>
    </div>
  );
}
