import useSWR from 'swr';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { PageParty } from 'types/strapi';

export default function usePartyPage() {
  const { data, error } = useSWR<StrapiResponseSuccess<PageParty>>([`/page-party`], get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = !data && !error;

  return {
    loading,
    page: data,
  };
}
