import SeoHead from 'components/shared/SeoHead';
import styles from './Parties.module.css';
import PartiesGrid from 'components/shared/PartiesGrid';
import Layout from 'components/shared/Layout';
import Title from 'components/ui/Title';
import titleBackgroundImageUrl from 'assets/svg/BrushStrokes.svg';
import Button from 'components/ui/Button';

export default function Parties() {
  return (
    <>
      <SeoHead seo={{ metaTitle: 'All Ragers | Three Olives® Vodka' }} />
      <Layout>
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <div className={styles.titleWrapper}>
              <img className={styles.titleBackgroundImage} src={titleBackgroundImageUrl} alt="" />
              <Title size="large" className={styles.title}>
                All Ragers
              </Title>
            </div>
            <PartiesGrid />
            <div className={styles.fixedButton}>
              <Button tag="a" href="/submission">
                Create my Rager
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
