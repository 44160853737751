export function transformIngredients(ingredients: string[]): string[] {
  return ingredients.map(
    (ingredient) =>
      ingredient
        .trim() // remove extra spaces before and after the string
        .split(/\s+/) // split the string into words
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize the first letter of each word
        .join(' ') // join the words back into a string
  );
}
