import { useEffect, useRef } from 'react';
import anime from 'animejs';
import 'splitting/dist/splitting.css';
import Splitting from 'splitting';

export default function AnimatedText({ children }: { children: string }) {
  const el = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (!el.current) return;
    Splitting({
      /* target: String selector, Element, Array of Elements, or NodeList */
      target: '[data-splitting]',
      /* by: String of the plugin name */
      by: 'chars',
      /* key: Optional String to prefix the CSS variables */
      key: null,
    });

    anime
      .timeline({
        begin: () => {
          el.current?.style.setProperty('opacity', '1');
        },
      })
      .add({
        targets: el.current?.querySelectorAll('span[class^="char"]'),
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: 'easeOutExpo',
        duration: 1200,
        delay: (el, i) => 300 + 30 * i,
      });
  }, [children]);

  return (
    <span data-splitting style={{ opacity: 0 }} ref={el} key={children}>
      {children}
    </span>
  );
}
