import useSWR from 'swr';
import { Flavour } from 'types/strapi';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { populate } from 'utils/populate';

export default function useFlavours() {
  const { data, error } = useSWR<StrapiResponseSuccess<Flavour[]>>([`/flavours?${populate.flavours}`], get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = !data && !error;

  return {
    loading,
    flavours: data,
  };
}
