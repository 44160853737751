import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';
import honeybadger from 'utils/honeybadger';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <SWRConfig
        value={{
          onError: (error, key) => {
            if (error.status !== 403 && error.status !== 404) {
              honeybadger.notify(error, {
                context: {
                  key,
                },
              });
            }
          },
        }}
      >
        <App />
      </SWRConfig>
    </BrowserRouter>
  </React.StrictMode>
);
