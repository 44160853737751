import useSWR from 'swr';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { populate } from 'utils/populate';
import { PageSubmission } from 'types/strapi';

export default function useSubmissionPage() {
  const { data, error } = useSWR<StrapiResponseSuccess<PageSubmission>>(
    `/page-submission?${populate.pageSubmission}`,
    get,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  const loading = !data && !error;

  return {
    loading,
    page: data,
  };
}
