import useSWR from 'swr';
import { Flavour } from 'types/strapi';
import { MainPrompt } from 'types/strapi';
import { useCallback } from 'react';
import { Cocktail } from 'types/strapi';

export default function useSubmissionData() {
  const { data, mutate } = useSWR<SubmissionData>('submission-data', {
    fallbackData: initialStore,
  });

  const reset = useCallback(() => {
    mutate(initialStore, false);
  }, [mutate]);

  return {
    submissionData: data || initialStore,
    mutate,
    reset,
  };
}

const initialStore = {
  name: '',
  description: '',
  generatedImages: [],
  ingredients: [],
  flavour: null,
  mainPrompt: null,
  cocktail: null,
};

export interface SubmissionData {
  name: string;
  description: string;
  generatedImages: string[];
  ingredients: string[];
  flavour: Flavour | null;
  mainPrompt: MainPrompt | null;
  cocktail: Cocktail | null;
}
