import clsx from 'clsx';
import Footer from './Footer';
import HeaderLogo from './HeaderLogo';
import styles from './Layout.module.css';
import videoSrc from 'assets/video/background-video.mp4';
import videoOverlaySrc from 'assets/img/video-overlay.png';

export default function Layout({
  children,
  withBackgroundImage,
  withBackgroundVideo,
}: {
  children: React.ReactNode;
  withBackgroundImage?: boolean;
  withBackgroundVideo?: boolean;
}) {
  return (
    <div
      className={clsx(
        styles.layout,
        withBackgroundImage && styles.layoutWithImage,
        withBackgroundVideo && styles.layoutWithVideo
      )}
    >
      {withBackgroundVideo && (
        <>
          <video autoPlay muted loop playsInline className={styles.backgroundVideo} src={videoSrc} />
          <img className={styles.backgroundVideoOverlay} src={videoOverlaySrc} alt="" />
        </>
      )}
      <HeaderLogo />
      {children}
      <Footer withoutBackground={withBackgroundImage} />
    </div>
  );
}
