let siteKey = process.env.REACT_APP_RECAPTCHA_ENTERPRIZE_SITE_KEY || '6LdlA9QoAAAAACk5MaPncww3CbXVclDP-pZBhnKn';

export function initRecaptchaEnterprise() {
  const url = `https://www.google.com/recaptcha/enterprise.js?render=`;
  const script = document.createElement('script');
  script.src = url + siteKey;
  document.head.appendChild(script);
  const style = document.createElement('style');
  style.innerHTML = '.grecaptcha-badge { visibility: hidden; pointer-events: none; }';
  document.head.appendChild(style);
}

export function execute(actionName: string): Promise<string> {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(async () => {
      try {
        const token = await window.grecaptcha.enterprise.execute(siteKey, { action: actionName });
        resolve(token);
      } catch (e) {
        reject(e);
      }
    });
  });
}

declare global {
  interface Window {
    grecaptcha: {
      enterprise: ReCaptchaV2.ReCaptcha;
    };
  }
}
