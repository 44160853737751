import SeoHead from 'components/shared/SeoHead';
import styles from './Parties.module.css';
import PartiesGrid from 'components/shared/PartiesGrid';
import Layout from 'components/shared/Layout';
import Title from 'components/ui/Title';
import titleBackgroundImageUrl from 'assets/svg/BrushStrokes.svg';

export default function ApproveParties() {
  return (
    <>
      <SeoHead seo={{ metaTitle: 'Approve ragers | Three Olives® Vodka' }} />
      <Layout>
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <div className={styles.titleWrapper}>
              <img className={styles.titleBackgroundImage} src={titleBackgroundImageUrl} alt="" />
              <Title size="large" className={styles.title}>
                Approve ragers
              </Title>
            </div>
            <PartiesGrid />
          </div>
        </div>
      </Layout>
    </>
  );
}
