import useSWR from 'swr';
import { Footer } from 'types/strapi';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { populate } from 'utils/populate';

export default function useFooter() {
  const { data, error } = useSWR<StrapiResponseSuccess<Footer>>([`/footer?${populate.footer}`], get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = !data && !error;

  return {
    loading,
    footer: data,
  };
}
