import useSWR from 'swr';

export default function useSubmittedPartiesHistory() {
  const { data, mutate } = useSWR<SubmissionData>('submitted-parties-history', {
    fallbackData: initialStore,
  });

  return {
    data: data || initialStore,
    mutate,
  };
}

const initialStore = {
  partiesId: [],
};

export interface SubmissionData {
  partiesId: number[];
}
