import useSWR from 'swr';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { PageSubmissionCompleted } from 'types/strapi';
import { populate } from 'utils/populate';

export default function useCompleteSubmissionPage() {
  const { data, error } = useSWR<StrapiResponseSuccess<PageSubmissionCompleted>>(
    [`/page-submission-completed?${populate.pageSubmissionCompleted}`],
    get,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const loading = !data && !error;

  return {
    loading,
    page: data,
  };
}
