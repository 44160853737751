import useSubmissionData from 'hooks/useSubmissionData';
import { Navigate } from 'react-router-dom';

export default function RequireSubmissionData({ children }: { children: JSX.Element }) {
  const { submissionData } = useSubmissionData();

  if (!submissionData.name) {
    return <Navigate to="/submission" replace />;
  }

  return children;
}
