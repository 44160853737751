import InfiniteScroll from 'react-infinite-scroll-component';
import styles from './PartiesGrid.module.css';
import Party from './Party';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { Party as PartyType } from 'types/strapi';
import useInfiniteList from 'hooks/useInfiniteList';
import { get } from 'utils/api';
import qs from 'qs';

export default function PartiesGrid({ searchValue }: { searchValue?: string }) {
  const { isEmpty, isReachingEnd, items, setSize, size } = useInfiniteList<StrapiResponseSuccess<PartyType[]>>({
    fetcher: get,
    pageSize: 6,
    getKey: (pageIndex, pageSize, previousPageData) =>
      `/parties?${qs.stringify({
        pagination: {
          page: pageIndex + 1,
          pageSize,
        },
        populate: {
          image: '*',
        },
        sort: ['featured:desc', 'id:desc'],
        filters: {
          approved: true,
        },
      })}`,
  });

  if (!items?.length) return null;

  return (
    <div>
      <InfiniteScroll
        dataLength={items.reduce((acc, res) => acc + res.data.length, 0)}
        next={() => setSize(size + 1)}
        hasMore={!isReachingEnd}
        loader={<div className={styles.loadingContainer}>Loading...</div>}
        endMessage={<>{isEmpty && <div>No ragers yet</div>}</>}
      >
        <div className={styles.parties}>
          {items.map((res) => res.data.map((item) => <Party key={item.id} party={item} />))}
        </div>
      </InfiniteScroll>
    </div>
  );
}
