import Button from 'components/ui/Button';
import Input from 'components/ui/Input';
import { setPasswordEntered } from 'utils/localStorage';
import styles from './Form.module.css';
import ErrorMessage from 'components/ui/ErrorMessage';
import { useState } from 'react';

export default function PasswordForm() {
  const [error, setError] = useState('');

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError('');

    const formData = new FormData(e.currentTarget);
    const password = formData.get('password');
    if (password === PASSWORD) {
      setPasswordEntered();
      window.location.href = '/';
    } else {
      setError('Invalid password');
    }
  };

  return (
    <form className={styles.formSmall} onSubmit={onSubmit}>
      <Input type="password" required name="password" placeholder="Enter password" />
      <div className={styles.buttonWrapper} style={{ marginBottom: '1.5rem' }}>
        <Button tag="button" type="submit">
          Submit
        </Button>
      </div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </form>
  );
}

const PASSWORD = 'GeneragerXTO2023';
