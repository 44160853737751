import clsx from 'clsx';
import styles from './Checkbox.module.css';

export const Checkbox = (props: {
  name: string;
  required?: boolean;
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <label className={clsx(styles.checkboxWrapper, props.className)}>
      <input name={props.name} type="checkbox" required={props.required} />
      <span>{props.children}</span>
    </label>
  );
};
