import useSWR from 'swr';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { Party } from 'types/strapi';
import { populate } from 'utils/populate';

export default function useParty(slug: string) {
  const { data, error, mutate } = useSWR<StrapiResponseSuccess<Party[]>>(`/parties/?${populate.party(slug)}`, get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = !data && !error;

  return {
    loading,
    party: data?.data[0],
    mutate,
  };
}
