import useSWR from 'swr';
import { Prompt } from 'types/strapi';
import { StrapiResponseSuccess } from 'types/strapiResponse';
import { get } from 'utils/api';
import { populate } from 'utils/populate';

export default function usePrompts() {
  const { data, error } = useSWR<StrapiResponseSuccess<Prompt[]>>([`/prompts?${populate.prompts}`], get, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const loading = !data && !error;

  return {
    loading,
    prompts: data,
  };
}
