import SeoHead from 'components/shared/SeoHead';
import styles from './Submission.module.css';
import Layout from 'components/shared/Layout';
import useSubmissionForm from 'hooks/useSubmissionForm';
import ErrorMessage from 'components/ui/ErrorMessage';
import Button from 'components/ui/Button';
import Field from 'components/ui/Field';
import Input from 'components/ui/Input';
import Title from 'components/ui/Title';
import Markdown from 'components/ui/Markdown';
import LoadingText from 'components/shared/LoadingText';
import clsx from 'clsx';
import useSubmissionPage from 'hooks/useSubmissionPage';
import { ReactComponent as RefreshIcon } from 'assets/svg/refresh.svg';
// import bottleImageSrc from 'assets/img/750_Cherry_Front_PNG_WHITE2.png';

export default function Submission() {
  const { page } = useSubmissionPage();
  let { loading, error, onSubmit, moderationFailed, resetSubmission, flavours } = useSubmissionForm();

  if (!page || !flavours.length) return null;

  const {
    seo,
    copy,
    formInput1Placeholder,
    formInput2Placeholder,
    formInput3Placeholder,
    formSubmitButtonLabel,
    formTitle,
    subtitle,
    title,
  } = page.data.attributes;

  return (
    <>
      <SeoHead seo={seo} />
      <Layout withBackgroundVideo={loading}>
        {loading && (
          <div className={styles.loaderWrapper}>
            <LoadingText withoutBottle={true} />
          </div>
        )}
        {error && (
          <div className={styles.errorWrapper}>
            <div>
              <ErrorMessage children={error} />
              {moderationFailed && (
                <div className={styles.tryAgainButtonWrapper}>
                  <Button
                    tag="button"
                    type="button"
                    onClick={resetSubmission}
                    variant="link"
                    className={styles.tryAgainButton}
                  >
                    <Title size="xsmall" tag="span" color="secondary" className={styles.tryAgainButtonLabel}>
                      Try again
                    </Title>
                    <RefreshIcon />
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
        {!loading && !error && (
          <div className={styles.container}>
            <div className={styles.containerInner}>
              <div className={clsx(styles.columns)}>
                <div className={styles.column1}>
                  <Title size="xlarge" color="secondary">
                    {title}
                  </Title>
                  <div className={styles.blockWithLeftSpace}>
                    {subtitle && (
                      <Title size="small" className={styles.subtitle}>
                        <Markdown>{subtitle}</Markdown>
                      </Title>
                    )}
                    <Markdown className={styles.copy}>{copy}</Markdown>
                  </div>

                  {/* <div className={styles.bottleImageColumns}>
                  <div className={styles.bottleImageColumn}>
                    <img src={bottleImageSrc} className={styles.bottleImage} alt="" width={220} height={1000} />
                  </div>
                  <div>
                    {subtitle && (
                      <Title size="small" className={styles.subtitle}>
                        <Markdown>{subtitle}</Markdown>
                      </Title>
                    )}
                    <Markdown className={styles.copy}>{copy}</Markdown>
                  </div>
                </div> */}
                </div>
                <div className={styles.column2}>
                  <form className={styles.form} onSubmit={onSubmit}>
                    <Title size="small" className={styles.formTitle}>
                      {formTitle}
                    </Title>
                    <div className={styles.fields}>
                      <Field>
                        <Input name="ingredient1" placeholder={formInput1Placeholder} required />
                      </Field>
                      <Field>
                        <Input name="ingredient2" placeholder={formInput2Placeholder} required />
                      </Field>
                      <Field>
                        <Input name="ingredient3" placeholder={formInput3Placeholder} required />
                      </Field>
                    </div>
                    <div className={styles.buttonWrapper}>
                      <Button className={styles.button} tag="button" type="submit">
                        {formSubmitButtonLabel}
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
}
