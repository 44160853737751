export function replaceDescription(description: string, cocktailName?: string, cocktailLink?: string) {
  if (cocktailName && cocktailLink) {
    // const cockailNameWithoutBrand = cocktailName.split('Three Olives')[1].trim();
    // const cocktailNameWithBrandFound = description.includes(cocktailName);
    // description = description.replaceAll(
    //   cocktailNameWithBrandFound ? cocktailName : cockailNameWithoutBrand,
    //   `**${cocktailName}**`
    // );
  }
  description = description.replaceAll('Dress Code:', '**Dress Code:**');
  description = description.replaceAll('Dress code:', '**Dress code:**');
  description = description.replaceAll('®', ' _®_');
  return description;
}
