import Layout from 'components/shared/Layout';
import SeoHead from 'components/shared/SeoHead';
import Markdown from 'components/ui/Markdown';
import Title from 'components/ui/Title';
import useParty from 'hooks/useParty';
import usePartyPage from 'hooks/usePartyPage';
import { Link, useParams } from 'react-router-dom';
import getStrapiMediaUrl from 'utils/getStrapiMediaUrl';
import styles from './Party.module.css';
import { ReactComponent as IconBack } from 'assets/svg/back-icon.svg';
import { parseIngredients } from 'utils/parseIngredients';
import Share from 'components/shared/Share';
import Balancer from 'react-wrap-balancer';
import AnimatedText from 'components/shared/AnimatedText';
import Button from 'components/ui/Button';
import useSubmittedPartiesHistory from 'hooks/useSubmittedPartiesHistory';
import { transformIngredients } from 'utils/transformIngredients';
import Error404 from './Error404';

export default function Party() {
  const { data: submittedPartiesHistoryData } = useSubmittedPartiesHistory();
  const { slug } = useParams() as {
    slug: string;
  };
  const { party, loading } = useParty(slug);
  const { page } = usePartyPage();

  // const [file, setFile] = useState<File | undefined>(undefined);
  // useEffect(() => {
  //   if (!image?.data.attributes.url) return;
  //   (async () => {
  //     const file = await urlToFile(getStrapiMediaUrl(image?.data.attributes.url));
  //     setFile(file);
  //   })();
  // }, [image?.data?.attributes?.url, setFile]);

  if (!page || loading) return null;
  if (!party) return <Error404 />;

  const { ingredients = '', image, name = '', description = '' } = party.attributes;
  const {
    seo,
    prePartyName,
    backLinkLabel,
    submissionCompletedSubtitle,
    submissionCompletedTitle,
    submissionCompletedCopy,
  } = page.data.attributes;
  const isSubmittedByUser = submittedPartiesHistoryData.partiesId.includes(party.id);
  const buttonLabel = isSubmittedByUser ? 'Create new rager' : 'Create my rager';
  const formattedIngredients = transformIngredients(parseIngredients(ingredients));

  return (
    <>
      <SeoHead seo={{ ...seo, metaTitle: name + ' | Three Olives® Vodka' }} />
      <Layout>
        {isSubmittedByUser && (
          <div className={styles.thankYouHeader}>
            <Title className={styles.thankYouHeaderTitle} size="large" color="secondary">
              <Balancer>{submissionCompletedTitle}</Balancer>
            </Title>
            <Title className={styles.thankYouHeaderSubtitle} size="medium" color="secondary">
              <Balancer>{submissionCompletedSubtitle}</Balancer>
            </Title>
            <Markdown className={styles.thankYouHeaderCopy}>{submissionCompletedCopy}</Markdown>
            <div className={styles.shareByAuthor}>
              <Share
                text={`Hey, check out this rager created by Three Olives Vodka AI Generager!`}
                url={window.location.href}
                imageUrl={getStrapiMediaUrl(image?.data?.attributes?.url || '')}
              />
            </div>
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <div className={styles.columns}>
              <div className={styles.column}>
                <Link className={styles.backLink} to="/all-ragers">
                  <IconBack />
                  <Title size="xsmall" color="secondary" className={styles.backLinkLabel}>
                    {backLinkLabel}
                  </Title>
                </Link>
                <div className={styles.partyImageMobileWrapper}>
                  <img
                    className={styles.partyImageMobile}
                    src={getStrapiMediaUrl(image?.data?.attributes?.url || '')}
                    alt=""
                    width={896}
                    height={1152}
                  />
                </div>
                <Title size="xsmall" color="primarydark">
                  {prePartyName}
                </Title>
                <Title size="medium" className={styles.partyName}>
                  <Balancer>
                    <AnimatedText>{name}</AnimatedText>
                  </Balancer>
                </Title>
                <Markdown className={styles.content}>{description}</Markdown>
                <div className={styles.ingredients}>
                  <strong>Ingredients:</strong>
                  <div>{formattedIngredients.join(', ')}</div>
                </div>
                <div className={styles.buttonWrapper}>
                  <Button href="/submission" tag="a">
                    {buttonLabel}
                  </Button>
                </div>
              </div>
              <div className={styles.column}>
                <img
                  className={styles.partyImage}
                  src={getStrapiMediaUrl(image?.data?.attributes?.url || '')}
                  alt=""
                  width={896}
                  height={1152}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
