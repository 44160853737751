import styles from './Party.module.css';
import { Party as IParty } from 'types/strapi';
import Title from 'components/ui/Title';
import getStrapiMediaUrl from 'utils/getStrapiMediaUrl';
import { Link } from 'react-router-dom';
import Balancer from 'react-wrap-balancer';
import { transformIngredients } from 'utils/transformIngredients';
import { parseIngredients } from 'utils/parseIngredients';

export default function Party({ party }: { party: IParty }) {
  const { ingredients, name, image, authorFirstName, authorLastName } = { ...party.attributes };
  const authorFullName = `${authorFirstName || ''} ${authorLastName || ''}`.trim();
  const formattedIngredients = transformIngredients(parseIngredients(ingredients));

  return (
    <Link to={`/all-ragers/${party.attributes.slug}`} className={styles.party}>
      <img src={getStrapiMediaUrl(image?.data?.attributes?.url || '')} alt="" width={896} height={1152} />
      <Title size="small" className={styles.partyName}>
        <Balancer>{name}</Balancer>
      </Title>
      <div>
        <strong>Ingredients:</strong>
        <div>{formattedIngredients.join(', ')}</div>
        {authorFullName && <div className={styles.partyAuthor}>by {authorFullName}</div>}
      </div>
    </Link>
  );
}
