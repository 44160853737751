import SeoHead from 'components/shared/SeoHead';
import styles from './Home.module.css';
import Layout from 'components/shared/Layout';
import Title from 'components/ui/Title';
import Markdown from 'components/ui/Markdown';
import Button from 'components/ui/Button';
import useHomePage from 'hooks/useHomePage';
import bottleImageSrc from 'assets/img/750_Cherry_Front_PNG_WHITE2.png';

export default function Home() {
  const { page } = useHomePage();

  if (!page) return null;

  const { seo, copy, ctaLabel, subtitle, title, cta2Label } = page.data.attributes;

  return (
    <>
      <SeoHead seo={seo} />
      <Layout withBackgroundImage={true}>
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <div className={styles.columns}>
              <div className={styles.column1}>
                <Title color="secondary" size="xlarge">
                  {title}
                </Title>
                <div className={styles.blockWithLeftSpace}>
                  <Title className={styles.subtitle} size="small">
                    {subtitle}
                  </Title>
                  <Markdown className={styles.copy}>{copy}</Markdown>
                  <div className={styles.buttons}>
                    <Button className={styles.button} tag="a" href="/submission" variant="outlined">
                      {ctaLabel}
                    </Button>
                    <Button className={styles.button} tag="a" href="/all-ragers">
                      {cta2Label}
                    </Button>
                  </div>
                </div>
              </div>
              <div className={styles.column2}>
                <img src={bottleImageSrc} className={styles.bottleImage} alt="" width={806} height={1000} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
