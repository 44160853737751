import useSWRInfinite from 'swr/infinite';
import { useEffect } from 'react';
import type { StrapiResponseSuccess } from 'types/strapiResponse';

export default function useInfiniteList<T extends StrapiResponseSuccess<Record<string, any>[]>>({
  fetcher,
  pageSize = 20,
  getKey,
}: {
  fetcher: (url: string) => Promise<T>;
  pageSize?: number;
  getKey: (pageIndex: number, pageSize: number, previousPageData: T | null) => string | null;
}) {
  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite<T>(
    (pageIndex, previousPageData) => getKey(pageIndex, pageSize, previousPageData),
    fetcher,
    { revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  useEffect(() => {
    return () => {
      setSize(1);
    };
  }, [setSize]);

  const items = data?.length ? ([] as typeof data).concat(...data) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.data.length === 0;
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.data.length < pageSize);
  const isRefreshing = isValidating && data && data.length === size;

  return {
    isLoadingInitialData,
    isLoadingMore,
    isEmpty,
    isReachingEnd,
    isRefreshing,
    items,
    setSize,
    size,
    mutate,
  };
}
