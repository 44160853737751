import React from 'react';
import { Link } from 'react-router-dom';

export default function StrapiLink(props: {
  className?: string;
  children: React.ReactNode;
  url?: string;
  openInNewTab?: boolean;
  onClick?: (event: any) => void;
}) {
  const isRelativeLink = props.url?.charAt(0) === '/';

  if (!props.url) return null;

  // External link
  if (!isRelativeLink) {
    return (
      <a
        className={props.className}
        href={props.url}
        target={props.openInNewTab ? '_blank' : undefined}
        rel="noopener noreferrer"
        onClick={props.onClick}
      >
        {props.children}
      </a>
    );
  }
  // Internal link
  return (
    <Link onClick={props.onClick} to={props.url} className={props.className}>
      {props.children}
    </Link>
  );
}
