import { Honeybadger } from '@honeybadger-io/react';

const config = {
  apiKey: 'hbp_7nNtZSvVKp9k2a4GexrWPHKKEmD0wl1bmuT0',
  environment: process.env.NODE_ENV || 'production',
};

const honeybadger = Honeybadger.configure(config);

honeybadger.beforeNotify((notice) => {
  // Ignore ResizeObserver loop errors
  if (notice?.message === 'ResizeObserver loop completed with undelivered notifications.') {
    return false;
  }
});

export default honeybadger;
