import styles from './Rules.module.css';

export default function Rules() {
  return <div className={styles.rules} dangerouslySetInnerHTML={{ __html: html }} />;
}

const html = `
<p align="CENTER">
<u>Three Olives</u><sup><u>®</u></sup> <u>Vodka The Generager Contest</u>
</p>
<p align="CENTER">
Official  Rules
</p>
<p align="JUSTIFY">
NO  PURCHASE NECESSARY TO ENTER OR WIN.  MAKING A PURCHASE DOES NOT  IMPROVE
CHANCES OF WINNING.  THE FOLLOWING CONTEST IS VALID ONLY IN  THE FIFTY (50)
UNITED STATES (“U.S.”) AND DISTRICT OF COLUMBIA  (“D.C.”), AND IS OPEN ONLY
TO QUALIFIED PERSONS (AS DEFINED IN  THE OFFICIAL RULES BELOW) WHO ARE
TWENTY-ONE (21) YEARS OF AGE OR  OLDER AS OF THE DATE OF ENTRY.  VOID
ELSEWHERE AND WHEREVER  PROHIBITED.  BY PARTICIPATING IN THIS CONTEST, YOU
AGREE TO BE BOUND  BY THESE OFFICIAL RULES AND THE DECISIONS OF SPONSOR AND
ADMINISTRATOR.  DO NOT PARTICIPATE IN THE CONTEST IF YOU DO NOT AGREE  TO
THESE OFFICIAL RULES AND FALL WITHIN THE ELIGIBILITY REQUIREMENTS  AS SET
FORTH BELOW.
</p>
<p align="JUSTIFY">
<strong>Contest  Name:</strong>Three Olives Vodka  The Generager Contest
(the “Contest”).
</p>
<p align="JUSTIFY">
<strong>Sponsor:</strong> Proximo Spirits, Inc., 3 Second Street, Suite
1101, Jersey City, NJ  07302 (“Sponsor”).
</p>
<p>
<strong>Administrator:</strong> Million Dollar Media, P.O. Box 278,
Woodbridge, NJ 07095  (“Administrator”).
</p>
<p align="JUSTIFY">
<strong>Contest  Dates:</strong>The Contest  registration begins at 12:00:01
am Eastern Time (“ET”) on <strong>October  3, 2023</strong> and ends at
11:59:59 pm ET on <strong>December  15, 2023</strong> (the “Registration
Period”).  The Contest is  subject to all applicable federal, state and
local laws and  regulations.  Void outside the  fifty (50) U.S. and D.C.,
and  wherever prohibited or restricted by law.
</p>
<p align="JUSTIFY">
<strong>Who may</strong> <strong>Enter:</strong> The Contest is open only to
legal U.S. residents, who are age  twenty-one (21) or older as of the date
of entry and have a valid  U.S. driver’s license or government issued photo
I.D., except the  following persons who are not eligible to enter,
participate or win:  employees, officers, and directors of Sponsor,
Administrator, each of  their respective franchisees, parents, affiliated
companies,  subsidiaries, wholesalers, distributors, alcoholic beverage
retailers, printers, advertising and promotion agencies, and any and  all
other companies associated with the design or execution of this  Contest,
and the immediate family members or households, whether or  not related, of
any of the above. “Immediate family members”  includes, for purposes of this
Contest, parents (including in-laws  and step-parents), grandparents,
siblings (including step-siblings  and half-siblings), children (including
step-children), grandchildren  (including step grandchildren) and each of
their respective spouses,  regardless of where they reside.
</p>
<p align="JUSTIFY">
<strong>How  to Enter –</strong> <strong>Online  Registration:</strong> To
enter <em>,</em> visit the Contest website at
<u>
    <a href="http://www.ThreeOlives.com/TheGenerager">www.ThreeOlives.com/TheGenerager</a>
</u>
(the “Contest Website”) during the Registration Period.  Each  entrant must
fully complete the online registration page with all  required information,
including his/her name, valid email address,  phone number
<em>
    (including  area code)
</em>
,  address <em>(including  city, state and zip)</em>,  date of birth and any
other information requested on the Contest  Website.  Each entrant must
select and submit three (3) ingredients  for their party idea into the AI
powered party generator on the  contest site to generate a unique party
idea.  After receiving the  party idea, each entrant must follow the online
instructions to  submit their party idea on the registration page for it to
be  considered for the Contest.  Any individual generating a party idea  but
failing to submit the idea on the contest registration page shall  not be
eligible for the Contest  Entrants have the option to generate  multiple
party ideas prior to submitting their selected party idea  option for the
Contest.   After having read and agreed to these  Official Rules, click the
submit button to submit your online entry.
</p>
<p align="JUSTIFY">
<strong>
    By  your entry into the Contest, you are providing express written
    consent to Sponsor, Administrator and their respective designees to
    contact you with promotional messages using
    automated/pre-recorded/artificial technology.
</strong>
</p>
<p align="JUSTIFY">
<strong>
    All  entries must be submitted by 11:59:59p ET on December 15, 2023 to
    be  eligible.  Maximum of one (1) entry per person per day during the
    Registration Period.
</strong>
Each  entrant must submit an entry on his/her own behalf.  Any entry
submitted on behalf of another individual or using another person’s  name,
email, or providing any false or inaccurate information, fake  accounts or
duplicate accounts will be disqualified and ineligible to  claim any prize.
Any attempt by any entrant to obtain more than the  stated number of entries
allowed by using the same or  multiple/different accounts, emails,
identities or any other methods  void that entrant's entries and that
entrant may be disqualified. No  mechanically produced or automated entries
permitted.
<strong>
    Use  of any automated system to participate in the Contest is
    prohibited,  will result in disqualification and all such entries will
    be deemed  void.  False and/or deceptive entries or acts, including
    misrepresentation of age, email or address, shall render such entries
    ineligible.
</strong>
</p>
<p align="JUSTIFY">
All  entries in the Contest will become the sole property of Sponsor and
will not be returned.  Sponsor reserves the right to disqualify and  remove
any entry at any time that is not in compliance with these  Official Rules.
All entries must conform to the entry requirements  as outlined above and
below, or it may be disqualified and not  considered for the Contest.
</p>
<p align="JUSTIFY">
All  entries must include all required information.  Any entry with
incorrect, incomplete or illegible information or other inaccurate
information will be disqualified.  Sponsor, Administrator, and their
respective equity holders, owners, partners, members, directors,  managers,
officers, employees, consultants, contractors, lenders,  advisors, sponsors,
volunteers, insurers, agents, attorneys,  representatives, successors and
assigns (“Released  Parties”) are not responsible for lost, late,
misdirected,  incorrect, illegible, incomplete, invalid, unintelligible or
damaged  entries, or for any entry submitted in a manner that is not
expressly  allowed under these Official Rules, or for any entry not
submitted or  received due to any technical error or failure, human error,
unauthorized human intervention, inaccurate capture or mis-entry of  any
required information, failures, omissions, interruptions,  deletions, or
defects of any phone or cellular phone provider,  network computer online
systems, computer equipment, servers or  software, including any injury or
damage to entrant’s or any other  person’s computer relating to or resulting
from participation in  this Contest; or the failure to capture any such
information or  effects of hackers or failure of any electronic equipment,
computer,  phone line or cellular transmissions and/or network connections;
all  of such entries will be disqualified.
</p>
<p align="JUSTIFY">
In  the event of a dispute as to any entry, the authorized account holder
of the email address used to register, and associated with the entry  will
be deemed to be the registrant and s/he must be eligible  according to these
Official Rules. The “authorized email account  holder” is the natural person
assigned an email address by an  Internet access provider, online service
provider or other  organization responsible for assigning email addresses
for the domain  associated with the submitted address.  Proof of being the
authorized  account holder may be required.
</p>
<p align="JUSTIFY">
<strong>
    By  participating and submitting an entry, entrants agree that Sponsor
    and participating parties are authorized to contact them via email,
    mail, phone, or any other form of media to make entrants aware of
    information pertinent to the Contest, and to distribute information
    regarding the Sponsor(s) featured products, special events or
    promotional offers.  Entrants may opt-out of any further email
    communication at any time by simply replying REMOVE to any email
    message, or following the provided opt-out instructions.
</strong>
<em><strong></strong></em><strong>Any available o</strong>
<strong>
    pt-in  opportunities are not required to enter the Contest, and checking
    the  opt-in boxes will not improve your chances of winning.
</strong>
</p>
<p align="JUSTIFY">
<strong>
    By  registering and submitting an entry, you affirm that you have read
    and accepted these Official Rules.
</strong>
The  laws of the U.S. apply to and govern this Contest and any claims must
be raised and resolved in the U.S.  Any information collected by
registering and entering the Contest shall be used only in a manner
consistent with these Official Rules and with Sponsor’s Privacy  Policy. By
entering the Contest, each entrant agrees that s/he may be  contacted,
recorded and/or broadcasted by Sponsor, and that his/her  comment, may be
used, promoted, released, broadcasted or published by  Sponsor in any manner
related to the promotion or advertising of the  Contest.
<strong>
    Personal  information collected in connection with the Contest will be
    collected and used in accordance with Sponsor’s Privacy Policy as
    posted at proximospirits.com/privacy/.
</strong>
</p>
<p align="JUSTIFY">
<strong>General  Party Idea Submission Requirements:</strong>
</p>
<ol>
<li>
    <p align="JUSTIFY">
        Each          entry and party idea must be submitted in a format as
        accepted by          the Contest Website, and must be the entrant’s
        own original          creation (generated by utilizing the three (3)
        ingredients selected          and submitted into the AI powered
        party idea generator) and not          previously submitted in the
        Contest; and
    </p>
</li>
<li>
    <p align="JUSTIFY">
        Any          entry that is not properly submitted or received for
        any reason,          including failing to submit the AI generated
        party idea on the          registration page, any technical
        difficulties, upload failures,          failures to save or properly
        store the entry and/or photo, busy          signals, efforts of
        hackers or any lost or disconnected Internet          connections,
        will be disqualified.
    </p>
</li>
</ol>
<p align="JUSTIFY">
<strong>Additional  Party Idea Submission Requirements:</strong>Sponsor
reserves the right to, in its sole and absolute discretion,  disqualify any
entry and/or submission for any reason that it  considers to not be
appropriate for the Contest.  Entry submission  restrictions include, but
are not limited to, the following  requirements:
</p>
<ul>
<li>
    <p align="JUSTIFY">
        may          not include, depict or reference any individual under
        the age of          twenty-one (21), or that appears to be under the
        age of twenty-one          (21);
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          be, or ever have been, previously submitted in the
        Contest;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        except          as relates to the Sponsor’s brand(s), not include
        trademarks,          logos or copyrighted material not owned by
        entrant or material that          is used without permission
        (including, but not limited to, company          names, photographs,
        works of art or images published on any media)          or that
        otherwise infringes or violates the rights of any
        third-party (including, but not limited to, copyrights, trademarks,
        patents, trade secrets, logos, contract and licensing rights, rights
        of publicity or privacy, moral rights (i.e., “droit moral”) or
        any other intellectual property rights);
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          feature, display, refer to, or mention any companies or
        brands in          competition with Sponsor;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          include any unauthorized photos, images, comments or
        plagiarism of          any kind;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          contain or reference nudity, inappropriate, indecent,
        sexually          explicit, pornographic or obscene content or
        images (which shall be          determined by Sponsor, in its sole
        and absolute discretion);
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          contain any political or politically themed or
        motivated messages,          comments, statements or images;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          defame, slander, libel, misrepresent or contain
        disparaging remarks          or any other content that could
        adversely affect the name,          reputation or goodwill of the
        Released Parties or any other          individuals and/or entities
        affiliated or associated with Sponsor,          the Contest or any
        prizes, the determination of which shall remain          in the sole
        discretion of Sponsor;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          depict or include any comments, descriptions or images
        that may be          considered unlawful, harmful, threatening,
        violent, abusive,          harassing, tortuous, defamatory, vulgar,
        libelous, invasive of          another’s privacy, hateful, or
        racially, religiously, ethnically          or otherwise
        objectionable in any manner as solely determined by
        Sponsor;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          be subject to any third-party agreement or require
        payment or          incurrence of any sums to obtain further
        permissions of any person          or entity as a result of its use
        or exploitation of the comment(s),          photo, idea or rights
        therein or portions, or modified versions or          derivative
        works thereof;
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          include any name, likeness, description, photograph or
        any other          identifying element, in whole or in part, of any
        person, living or          dead, without permission; and
    </p>
</li>
<li>
    <p align="JUSTIFY">
        not          depict, describe or promote over consumption or
        irresponsible          consumption of Three Olives Vodka or any
        other alcoholic beverage.
    </p>
</li>
</ul>
<p align="JUSTIFY">
<strong>
    Representation  and Release of Rights to Contest Entry and/or
    Submission:
</strong>
By submitting any entry, each entrant agrees that each entry and/or
submission becomes the sole and exclusive property of Sponsor, and  Sponsor
may publish, display, promote or utilize the submission,  content and/or
photo or video in any manner of its choosing in  relation to the Contest or
advertising of the Contest or Sponsor.   Entry into the Contest and upload
of any entry, comment and/or photo  or video constitutes permission for the
Sponsor and its designees to  use that entrant’s entry, comment, photo,
video, name, city and  state of residence and/or likeness for purposes of
advertising and  trade in any and all media now or hereafter known worldwide
in  perpetuity without limitation or further compensation, notification  or
permission, unless prohibited by law.  Submissions will not be  acknowledged
or returned by Sponsor.
</p>
<p align="JUSTIFY">
Sponsor  reserves the right to request proof from any entrant, at any time,
in  a form acceptable to Sponsor, of any permission required or possibly
required to use such entrant’s entry in the Contest.  Failure to  provide
such proof, if requested, may disqualify such entry from the  Contest.
</p>
<p>
<strong>
    Judging  of Party Idea Submissions – Determination of Prize Winners
    (maximum  of one (1) grand prize and thirty-three (33) runner-up first
    prize  winners):
</strong>
Winners shall be  determined as follows:
</p>
<ul>
<li>
    <p>
        <strong>Runner-Up          First Prize winners (33):</strong>A
        total of eleven (11) weekly selection dates shall be held on or
        about
        <strong>
            October 9, 2023;          October 16, 2023; October 23, 2023;
            October 30, 2023; November 6,          2023; November 13, 2023;
            November 20, 2023; November 27, 2023;          December 4, 2023;
            December 11, 2023;
        </strong>
        and<strong>December 18, 2023</strong>,<strong></strong>a panel of
        judges          as solely determined and appointed by
        Sponsor/Contest Administrator          shall review, select and
        designate a maximum of three (3) runner-up          first prize
        winning party idea submissions per weekly selection          dates
        <em>
            (for a total of thirty-three (33) runner-up first prize
            winners),
        </em>
        from among all eligible party idea submissions received as of the
        date and time of each selection date
        <em>
            (see          judging criteria below)
        </em>
        .
        <em>
            (Note:           Runner-up first prize winners shall still be
            eligible for inclusion          in the judging and determination
            of the grand prize winner.           However, grand prize winner
            shall be selected from among all          eligible entries
            received and not necessarily from among the          runner-up
            first prize winners.)
        </em>
    </p>
</li>
<li>
    <p>
        <strong>Grand          Prize winner (1):</strong>On or
        about <strong>December 18, 2023</strong>,<strong></strong>a panel of
        judges          as solely determined and appointed by
        Sponsor/Contest Administrator          shall review, select and
        designate a maximum of one (1) grand prize          and thirty-three
        (33) runner-up first prize winning party idea          submissions,
        from among all eligible party idea submissions received
        <em>
            (see          judging criteria below)
        </em>
        .
    </p>
</li>
</ul>
<p align="JUSTIFY">
<strong>
    Note:   There is a maximum of one (1) prize award per person or
    household  during the entire Contest Period, except as noted above that
    for this  Contest any runner-up first prize winner shall still be
    eligible for  consideration for the grand prize.
</strong>
If eligible entries are less than the scheduled number of prizes  available,
then associated prize award(s) shall not be awarded.
</p>
<p>
Only  the entrants whose party idea submissions are selected by the judges
shall be designated as potential prize winners and be eligible to  claim the
associated prize award <em>(see  prize description below)</em>,  subject  to
verification of eligibility, and compliance with these Official  Rules.  All
other  entries and submissions are ineligible to claim a prize award.
</p>
<p>
Party  idea submissions shall be judged by a Sponsor/Administrator-appointed
panel of judges<em></em>pursuant to the following guidelines, requirements
and criteria in the  determination of the potential winning photo
submission(s):
</p>
<ul>
<li>
    <p>
        Party          Idea submission that utilizes three (3) ingredients
        that are deemed          to demonstrates creativity, originality,
        uniqueness, fun and/or          other inspiration (33.33%
        of score);
    </p>
</li>
<li>
    <p>
        Party          idea submission with a party idea utilizing the three
        (3)          ingredients that demonstrates creativity, originality,
        uniqueness,          fun and/or other inspiration (33.33%
        of score);
    </p>
</li>
<li>
    <p>
        Party          idea submission that includes and/or best represents
        the Sponsor’s          brand, brand image and Sponsor’s product in a
        positive manner          (33.34% of score);
    </p>
</li>
</ul>
<p align="JUSTIFY">
<strong>Winner  Notification:</strong>The eligible  entrants whose entries
are selected by judges shall be designated as
<strong>
    potential prize winners
</strong>
of the associated prize as designated by Administrator,  subject to
verification of eligibility, and compliance with these  Official Rules.
Only the entrants whose entries are selected by  judges shall be eligible to
claim the associated prize award (see  below). All other entries and
submissions received will be  disqualified.
</p>
<p align="JUSTIFY">
Each  potential prize winner will be notified initially via phone and/or
email following their selection and determination as a potential  winner.
Potential winners must follow the provided instructions to  verify
eligibility and claim the prize.  If the Administrator is  unsuccessful in
its attempt to reach a selected potential prize  winner or winner fails to
respond as instructed within forty-eight  (48) hours of the initial
notification attempt to the email address  or phone number associated with
that entrant’s entry, then that  entrant may be disqualified and an
alternate potential prize winner  may be randomly selected from among the
remaining eligible entries  received and notified as per the procedures
outlined above.
</p>
<p align="JUSTIFY">
<strong>
    Note:  phone messages will not be left during the winner notification
    process.
</strong>
</p>
<p align="JUSTIFY">
During  prize winner notification, the selected potential prize winner must
confirm their eligibility, and indicate their willingness to accept  the
designated prize award (see below).  If the selected winner does  not meet
the eligibility requirements, is unable or unwilling to  accept the prize or
elects to decline the prize, s/he will be  disqualified and, at Sponsor’s
discretion and time permitting, an  alternate winner will be selected from
among all remaining eligible  entries and notified as per the procedures
outlined above <em>(time  permitting)</em>.  Any  disqualified entrant will
not receive any alternate prize  substitution or compensation.
<strong>
    Decisions  of the Sponsor and Administrator in the selection and
    determination  of prize winner and all matters relating to this Contest
    are final  and binding.
</strong>
</p>
<p align="JUSTIFY">
Each  prize winner shall be required to: (1) complete and submit, within
three (3) days of notification, a prize acceptance form, including
affidavit of eligibility and liability/publicity release (where  permitted)
and a W-9 form (for grand prize winner); (2) may be  required to present a
copy of a valid driver’s license or  government issued photo ID and valid
social security card as a  condition of participating or receiving any
prize; and (3) pay all  applicable taxes (including, but not limited to, all
local, state and  federal taxes) on their respective prize award, and in
regards to the  grand prize winner, will be issued a 1099 form reflecting
the value  of the prize award.
</p>
<p align="JUSTIFY">
<u><strong>Grand Prize Award (maximum one (1) prize award):</strong></u> The
eligible entrant designated as the grand prize winner shall be  awarded the
following prize:  a custom party planned and hosted by  the Sponsor
designated professional party planner including party  elements as solely
determined and designated by Sponsor such as  food/catering, non-alcoholic
beverages, decorations, music,  entertainment, and any other elements as
solely determined and  designated by the Sponsor appointed party planner,
based upon  availability.  Party shall be hosted at the winner’s home or
other  hosting location or venue in the metro area of the winner’s city of
residence, as solely determined and designated by Sponsor appointed  party
planner and in accordance with all applicable law.  The number  of guests
eligible to attend shall be as solely determined and  designated by the by
Sponsor appointed party planner, and each guest  must be twenty-one (21) or
older.  The prize award has an approximate  retail value (“ARV”) not to
exceed $20,000.
</p>
<p align="JUSTIFY">
<strong>NOTE:</strong>Any necessary travel  related costs to attend party
including transportation,  accommodations, airport/hotel transfers, parking,
incidental  expenses, and any other expenses not specified herein as being
included in the prize award are the sole responsibility of winner  and/or
guest(s).
</p>
<p align="JUSTIFY">
Date  of party is based upon availability, at the sole discretion of
Sponsor and designated party planner, and must be completed by  December 15,
2024 or prize shall be forfeit.  Notwithstanding the  fact that the actual
value of the grand prize may be lower than the  stated ARV, no difference in
value will be awarded to the winner.
</p>
<p align="JUSTIFY">
Once  party date has been confirmed and event has been planned, prize shall
be determined as awarded, and there shall be no opportunity to  reschedule
or cancel event, including due to weather.  In the event  the event is
delayed, rescheduled or cancelled for any reason, there  shall be no
alternate prize award or compensation for winner.  In the  event party is
unable to be booked or planned due to a Force Majeure  Event, as defined
below, Sponsor reserves the right, in its sole  discretion, to extend the
deadline for party date beyond December 15,  2024 or to award winner an
alternate prize award of approximate equal  value, in lieu of the party, and
such prize award shall be the full  and final prize award.
</p>
<p align="JUSTIFY">
Event  is subject to any COVID-19 testing and/or vaccination requirements
for attendance.  All costs associated with vaccination, testing and
quarantine requirements are solely the responsibility of winner and  guests.
</p>
<p align="JUSTIFY">
<u>
    <strong>Runner-Up First Prize Award (maximum thirty-three (33) winners):</strong>
</u>
Each eligible entrant designated as a runner-up first prize winner  shall be
awarded: one (1) $100 Reserve Bar gift card, subject to the  terms and
conditions of the Reserve Bar gift card provider policy.   ARV of each
runner-up first prize award: $100.
</p>
<p align="JUSTIFY">
<u><strong>General Prize Conditions:</strong></u>
<strong>
    No alcoholic beverage is part of any prize award.
</strong>
All other costs and expenses related to prize acceptance, delivery,
redemption or use not specified herein as being provided are the sole
responsibility of winner.  All income, federal, state and local taxes  are
the winner’s sole responsibility.  All prizes are awarded as  is, without
any warranty, express or implied, by Sponsor.  All prize  awards are
non-transferable and no cash redemption or prize  substitution allowed,
except at Sponsor’s sole discretion or as  provided herein.  Sponsor
reserves the right to substitute a prize  with one (1) of comparable or
greater value, at its sole discretion.   Failure to collect or  properly
claim prize in accordance with these Official Rules will  result in
forfeiture of the prize.  If forfeited for any reason,  entrant will not
receive any other prize substitution or  compensation<em>,</em>and the prize
will remain the property of Sponsor and will not be  awarded, except as
provided herein.  All  properly claimed prizes will be awarded.  Any
unclaimed prizes will  not be awarded.
</p>
<p align="JUSTIFY">
<strong>Additional  Rules and Restrictions:</strong>By  participating,
entrants agree to abide by and be bound by the  Official Rules and the
decisions of the Sponsor and Administrator,  which shall be final in all
matters relating to this Contest.  In the  event an entrant wins a prize,
and is later found to be in violation  of these Official Rules, he/she will
be required to forfeit the prize  or to reimburse Sponsor for the stated ARV
of the prize if such  violation is discovered after winner has used the
prize.   Participation in the Contest constitutes permission for the Sponsor
and its designees to use entrants’ names, cities and states of  residence
and/or likenesses for purposes of advertising and trade in  any and all
media now or hereafter known worldwide in perpetuity  without limitation or
further compensation, notification or  permission, unless prohibited by law.
False, fraudulent or deceptive  entries or acts shall render entrants
ineligible for any prize.
</p>
<p align="JUSTIFY">
By  participating, entrants agree to hold all Released Parties harmless
from any claims, losses, actions, or damages of any kind, whether  actual,
incidental or consequential, for injury (including, but not  limited to,
personal injury or death), damages, losses, loss of  personal property or
expenses arising out of or relating to entrant’s  participation in this
Contest, or the acceptance, possession or  use/misuse of any prize, or
participation in prize-related activities  (including, but not limited to,
travel or activity related thereto),  and to assume all liability therefor.
</p>
<p align="JUSTIFY">
Released  Parties shall not be liable to winner or any other person for
failure  to supply the prize or any part thereof by reason of any Force
Majeure Event.  The term “Force Majeure Event” as used herein  shall mean,
without limitation, the following:  acts of God; strikes,  lockouts or other
labor or industrial disturbances; acts of public  enemies; war;
cyber-attack; orders or restraints of any kind from any  governmental, civil
or military authority or from any of its  departments, agencies or officials
(except when such governmental  action results from a party’s failure or
refusal to comply with any  applicable law, rule, or regulation);
insurrections; riots; power  failure or surges; landslides; earthquakes;
fires; storms; lightning;  droughts; floods; explosions; pest damage;
viruses; plagues;  diseases; epidemics; pandemics; acts or omissions of
communications  or other carriers; and any other cause or event not
reasonably within  the control of the Released Parties.
</p>
<p align="JUSTIFY">
Entrants  are restricted to use of ordinary and typical computer equipment,
mobile phone equipment, internet access and cellular networks.  Sponsor and
Administrator disclaims all liability for the inability  of an entrant to
complete or continue an entry due to equipment  malfunction, busy lines,
inadvertent disconnections, acts beyond the  Sponsor or Administrator’s
control (including, without limitation,  a Force Majeure Event), or
otherwise. The Sponsor and Administrator  disclaims any liability for
entries not received due to technical  difficulties or transmission errors.
The Sponsor and Administrator  disclaims all liability for any delays,
mis-delivery, loss or failure  in the delivery of any item sent by mail,
courier, express,  electronic transmission, or other delivery method.
Released Parties  are not responsible for mechanical, technical, electronic,
communications, telephone, computer, hardware or software errors,
malfunctions or failures of any kind, including: failed, incomplete,
garbled or delayed transmission of online entries, traffic congestion  on
telephone lines, the Internet or at any website or lost or  unavailable
network connections which may limit an online entrant's  ability to
participate in the Contest, and any injury or damage to  entrant’s or any
other person’s computer or mobile phone related  to or resulting from
participating in or downloading any information  necessary to participate in
the Contest.  Released Parties are not  responsible for any other errors of
any kind, whether computer,  technical, typographical, printing, human or
otherwise, relating to  or in connection with the Contest, including,
without limitation,  errors which may occur in connection with the printing
or advertising  of this Contest, administration or execution of the Contest,
the  cancellation of the Contest, the processing of entries or in the
announcement of the prizes or prize winners.  Sponsor, in its sole
discretion, reserves the right to disqualify any person tampering  with the
entry process or the operation of the Contest Website.
</p>
<p align="JUSTIFY">
Sponsor  reserves the right to cancel, modify, or terminate the Contest for
any reason, including, but not limited to, fraud, misconduct,  regulatory
requirement, Force Majeure Event, or as required by  applicable law, or if
any technical failures, internet difficulty or  programming error destroy or
threaten the integrity of the Contest,  in the opinion of the
Sponsor/Administrator, or if a computer virus,  bug, or other technical
problem corrupts the administration,  security, or proper conduct of the
Contest as determined by  Sponsor/Administrator, in their sole discretion.
In the event of  early termination of the Contest, Sponsor reserves the
right to  determine any prize winners from among all eligible, non-suspect
entries received as of the time/date of such termination.
</p>
<p align="JUSTIFY">
<u><strong>IN CASE OF DISPUTES:</strong></u><strong></strong>By
participating in this  Contest, each entrant agrees that: (1) any and all
disputes, claims  and causes of action arising out of or in connection with
this  Contest, or any prizes awarded, shall be resolved individually,
without resort to any form of class action, and any judicial  proceeding
shall take place in a federal or state court within the  State of New York
in the County of New York; (2) any and all claims,  judgments and awards
shall be limited to actual out-of-pocket costs  incurred, including costs
associated with entering this Contest, and  in no event shall entrant be
entitled to receive attorneys' fees or  other legal costs; and, (3) under no
circumstances, shall entrant be  permitted to obtain awards for, and entrant
hereby waives all rights  to claim, punitive, incidental and consequential
damages, and any  other damages, other than for actual out-of-pocket
expenses, and any  and all rights to have damages multiplied or otherwise
increased. All  issues and questions concerning the construction, validity,
interpretation and enforceability of these Official Rules, or the  rights
and obligations of entrant and Sponsor in connection with this  Contest,
shall be governed by, and construed in accordance with the  laws of the
State of New York, without giving effect to any choice of  law or conflict
of law rules or provisions (whether of the State of  New York or any other
jurisdiction), that would cause the application  of the laws of any
jurisdiction other than the State of New York.
</p>
<p align="JUSTIFY">
<strong>DISCLAIMER  OF WARRANTIES:</strong> SPONSOR IS  PROVIDING THE
CONTEST ON AN “AS IS” AND “AS AVAILABLE” BASIS.   SPONSOR DOES NOT REPRESENT
OR WARRANT THAT THE CONTEST OR THE  TECHNOLOGY USED TO PROVIDE IT: (1) WILL
BE UNINTERRUPTED OR SECURE;  (2) WILL BE FREE OF DEFECTS, INACCURACIES OR
ERRORS; OR (3) WILL MEET  YOUR REQUIREMENTS OR ENABLE YOU TO PARTICIPATE IN
IT.  THE RELEASED  PARTIES MAKE NO WARRANTIES OTHER THAN THOSE MADE
EXPRESSLY IN THESE  OFFICIAL RULES, AND HEREBY DISCLAIM ANY AND ALL IMPLIED
WARRANTIES,  INCLUDING, WITHOUT LIMITATION, WARRANTIES OF FITNESS FOR A
PARTICULAR  PURPOSE, MERCHANTABILITY AND NON-INFRINGEMENT.
</p>
<p align="JUSTIFY">
<strong>Odds:</strong>Odds of winning a prize  award are dependent upon the
total number of eligible entries  received, the ability of entrant in the
selection of three (3)  ingredients to generate their party idea, and
decisions of judges.
</p>
<p align="JUSTIFY">
<strong>Official  Rules and Winner List:</strong> For  a copy of these
Official Rules or the name of prize winners, send a  self-addressed stamped
envelope to be received by February 15, 2024  to: Three Olives Vodka The
Generager Contest, c/o Million Dollar  Media, P.O. Box 278, Woodbridge, NJ
07095.  PLEASE SPECIFY which you  are requesting, official rules or winners
list.
</p>
<p align="JUSTIFY">
©2023  Proximo Spirits. Jersey City, NJ.
</p>
<p align="JUSTIFY">
Please  drink Three Olives <sup>®</sup> Vodka responsibly.
</p>`;
