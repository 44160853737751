import SeoHead from 'components/shared/SeoHead';
import styles from './CompleteSubmission.module.css';
import { Link } from 'react-router-dom';
import useCompleteSubmissionPage from 'hooks/useCompleteSubmissionPage';
import Title from 'components/ui/Title';
import Markdown from 'components/ui/Markdown';
import Layout from 'components/shared/Layout';
import Button from 'components/ui/Button';
import ErrorMessage from 'components/ui/ErrorMessage';
import { ReactComponent as IconBack } from 'assets/svg/back-icon.svg';
import { ReactComponent as RefreshIcon } from 'assets/svg/refresh.svg';
import { ReactComponent as ArrowBack } from 'assets/svg/arrow-back.svg';
import { ReactComponent as ArrowNext } from 'assets/svg/arrow-next.svg';
import PartyImage from 'components/shared/PartyImage';
import Balancer from 'react-wrap-balancer';
import useCompleteSubmissionForm from 'hooks/useCompleteSubmissionForm';
import getStrapiMediaUrl from 'utils/getStrapiMediaUrl';
import clsx from 'clsx';
import AnimatedText from 'components/shared/AnimatedText';
import LoadingText from 'components/shared/LoadingText';
import { createPortal } from 'react-dom';
import Modal from 'components/ui/Modal';
import Input from 'components/ui/Input';
import DateInput from 'components/ui/DateInput';
import { Checkbox } from 'components/ui/Checkbox';
import PhoneInput from 'components/ui/PhoneInput';
import { transformIngredients } from 'utils/transformIngredients';

export default function CompleteSubmission() {
  const { page } = useCompleteSubmissionPage();

  let {
    loading,
    error,
    activeGeneratedImage,
    setNextActiveImage,
    setPreviousActiveImage,
    setActiveGeneratedImage,
    party,
    flavourImageUrl,
    generateImages,
    generateText,
    regenImagesButtonDisabled,
    openModal,
    toggleModal,
    handleFormSubmit,
    generateTextWithImages,
    isTextGenerationAborted,
  } = useCompleteSubmissionForm();

  if (!page || !party.name || !flavourImageUrl) return null;

  const {
    seo,
    title,
    backLinkLabel,
    imagesSelectorTitle,
    regenerateImagesButtonLabel,
    regenerateTextButtonLabel,
    modalFooter,
    modalTitle,
    submitButtonLabel,
    submitButtonNote,
    submitButtonNote2,
    modalSubmitButtonLabel,
    headerNote,
    modalSubtitle,
  } = page.data.attributes;
  const { generatedImages, ingredients, name, description } = party;
  const formattedIngredients = transformIngredients(ingredients);

  return (
    <>
      <SeoHead seo={seo} />
      <Layout>
        {headerNote && (
          <div className={styles.headerNote}>
            <Title size="xsmall" color="secondary">
              <Markdown>{headerNote}</Markdown>
            </Title>
          </div>
        )}
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <div className={styles.columns}>
              <div className={styles.column}>
                {/* Party content */}
                <div>
                  <Title size="xsmall" color="primarydark">
                    {title}
                  </Title>
                  <Title size="medium" className={styles.partyName}>
                    <Balancer>
                      <AnimatedText>{name}</AnimatedText>
                    </Balancer>
                  </Title>
                  <Markdown className={styles.partyDescription}>{description}</Markdown>
                  <div className={styles.ingredients}>
                    <strong>Ingredients:</strong>
                    <div>{formattedIngredients.join(', ')}</div>
                  </div>
                  {/* Regenerate text button */}
                  {((loading && !generatedImages.length && !isTextGenerationAborted) || !loading) && (
                    <Button
                      className={styles.regenarateImagesLink}
                      tag="button"
                      type="button"
                      variant="link"
                      onClick={async () => {
                        if (loading && !generatedImages.length) {
                          await generateTextWithImages();
                        } else {
                          await generateText();
                        }
                      }}
                    >
                      <Title size="xsmall" tag="span" color="secondary" className={styles.regenarateImagesLinkLabel}>
                        {regenerateTextButtonLabel}
                      </Title>
                      <RefreshIcon />
                    </Button>
                  )}
                </div>
                {/* Submit button and note */}
                {!loading && !error && activeGeneratedImage && (
                  <div className={styles.submitButtonWrapper}>
                    {/* Note */}
                    <Markdown>{submitButtonNote}</Markdown>
                    {/* Submit button */}
                    <Button
                      disabled={loading}
                      tag="button"
                      type="button"
                      onClick={() => {
                        toggleModal();
                      }}
                    >
                      {submitButtonLabel}
                    </Button>
                  </div>
                )}
                {/* Back Link and Note 2 */}
                {!loading && (
                  <>
                    {/* Note 2 */}
                    <Markdown className={styles.backLinkNote}>{submitButtonNote2}</Markdown>
                    {/* Back Link */}
                    <Link className={styles.backLink} to="/submission">
                      <IconBack />
                      <Title size="xsmall" color="secondary" className={styles.backLinkLabel}>
                        {backLinkLabel}
                      </Title>
                    </Link>
                  </>
                )}
              </div>
              <div className={styles.column}>
                {/* Loading */}
                {loading && (
                  <div className={styles.loadingWrapper}>
                    <LoadingText />
                  </div>
                )}

                {/* Error */}
                {error && (
                  <div className={styles.errorWrapper}>
                    <ErrorMessage>{error}</ErrorMessage>
                  </div>
                )}

                {/* Party active image */}
                {activeGeneratedImage && !loading && !error && (
                  <div className={styles.partyImageContainer}>
                    <div className={styles.partyImageWrapper}>
                      <PartyImage
                        imageUrl={`data:image/png;base64,${activeGeneratedImage}`}
                        overlayImageUrl={getStrapiMediaUrl(flavourImageUrl)}
                        title={name}
                        subtitle={`Ingredients: ${ingredients.join(', ')}`}
                      />
                      <button onClick={setPreviousActiveImage} className={styles.partyImageArrowLeft} type="button">
                        <ArrowBack />
                      </button>
                      <button onClick={setNextActiveImage} className={styles.partyImageArrowRight} type="button">
                        <ArrowNext />
                      </button>
                    </div>
                  </div>
                )}

                {/* Party image selector */}
                {!!generatedImages?.length && !loading && !error && (
                  <div className={styles.imageSelector}>
                    <Title size="xsmall" color="primarydark">
                      {imagesSelectorTitle}
                    </Title>
                    <div className={styles.generatedImagesWrapper}>
                      <div className={styles.generatedImages}>
                        {generatedImages.map((generatedImage, index) => (
                          <div key={index}>
                            <button
                              className={clsx(
                                styles.generatedImageButton,
                                generatedImage === activeGeneratedImage && styles.generatedImageButtonActive
                              )}
                              type="button"
                              onClick={() => setActiveGeneratedImage(generatedImage)}
                              disabled={loading}
                            >
                              <img
                                src={`data:image/png;base64,${generatedImage}`}
                                width={896}
                                height={1152}
                                alt=""
                                style={{ backgroundColor: 'var(--color-white)' }}
                              />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                {/* Regenerate images button */}
                {!loading && !regenImagesButtonDisabled && (
                  <Button
                    disabled={loading}
                    className={styles.regenarateImagesLink}
                    tag="button"
                    type="button"
                    variant="link"
                    onClick={async () => {
                      await generateImages();
                    }}
                  >
                    <Title size="xsmall" tag="span" color="secondary" className={styles.regenarateImagesLinkLabel}>
                      {regenerateImagesButtonLabel}
                    </Title>
                    <RefreshIcon />
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Layout>

      {/* Screenshot */}
      {activeGeneratedImage && (
        <PartyImageScreenshotContainer>
          <PartyImage
            imageUrl={`data:image/png;base64,${activeGeneratedImage}`}
            overlayImageUrl={getStrapiMediaUrl(flavourImageUrl)}
            title={name}
            subtitle={`Ingredients: ${ingredients.join(', ')}`}
          />
        </PartyImageScreenshotContainer>
      )}

      {/* Modal */}
      {openModal && (
        <Modal onClose={() => toggleModal()}>
          <div className={styles.modal}>
            <Title size="medium" color="primary" className={styles.modalTitle}>
              {modalTitle}
            </Title>
            <Markdown className={styles.modalSubtitle}>{modalSubtitle}</Markdown>
            <form onSubmit={handleFormSubmit} className={styles.modalForm}>
              <div className={styles.modalFieldsWrapper}>
                <div className={styles.modalFields}>
                  <Input variant="secondary" required type="text" placeholder="First Name*" name="firstName" />
                  <Input variant="secondary" required type="text" placeholder="Last name*" name="lastName" />
                  <Input
                    className={styles.fullWidth}
                    variant="secondary"
                    required
                    type="email"
                    placeholder="Email*"
                    name="email"
                  />
                  <Input
                    className={styles.fullWidth}
                    variant="secondary"
                    required
                    type="email"
                    placeholder="Confirm Email*"
                    name="confirmEmail"
                  />
                  <PhoneInput
                    variant="secondary"
                    required
                    type="text"
                    placeholder="Phone Number (XXX-XXX-XXXX)*"
                    name="phone"
                  />
                  <DateInput
                    variant="secondary"
                    required
                    type="text"
                    placeholder="Date of Birth (MM/DD/YYYY)*"
                    name="birthday"
                  />
                  <Input variant="secondary" required type="text" placeholder="Zip Code*" name="postcode" />
                  <Input variant="secondary" required type="text" placeholder="State*" name="state" />
                  <Checkbox name="agree" required className={styles.fullWidth}>
                    <span>
                      I confirm that I have read and agree to the,{' '}
                      <a href="/rules" rel="noreferrer" target="_blank" style={{ textDecoration: 'underline' }}>
                        Official Rules
                      </a>{' '}
                      and the terms of the{' '}
                      <a
                        href="https://proximospirits.com/privacy"
                        rel="noreferrer"
                        target="_blank"
                        style={{ textDecoration: 'underline' }}
                      >
                        Privacy Policy*
                      </a>
                    </span>
                  </Checkbox>
                  <Checkbox name="subscribe" className={styles.fullWidth}>
                    Yes, I want to receive promotional automated messaging from Three Olives Vodka, including emails
                    with news, offers and contests.
                  </Checkbox>
                </div>
              </div>
              <div className={styles.modalButtonWrapper}>
                <Button className={styles.modalSubmitButton} disabled={loading} tag="button" type="submit">
                  {modalSubmitButtonLabel}
                </Button>
              </div>
            </form>
            <Markdown className={styles.modalFooter}>{modalFooter}</Markdown>
          </div>
        </Modal>
      )}
    </>
  );
}

const PartyImageScreenshotContainer = ({ children }: { children: React.ReactElement }) => {
  return createPortal(
    <div style={{ width: 0, height: 0, overflow: 'auto' }}>
      <div id="partyImage">{children}</div>
    </div>,
    document.body
  );
};
