import { useEffect, useRef, useState } from 'react';
import { generateText as generateTextApi, createParty, generateImagesWithCancellation } from 'utils/api';
import takeScreenshot from 'utils/takeScreenshot';
import { replacePromptKeys } from 'utils/replacePromptKeys';
import usePrompts from './usePrompts';
import useSubmissionData from './useSubmissionData';
import { serialize } from 'utils/serialize';
import { useNavigate } from 'react-router-dom';
import useFormCustomValidations from './useFormCustomValidations';
import useSubmittedPartiesHistory from './useSubmittedPartiesHistory';
import { replaceDescription } from 'utils/replaceDescription';
import logError from 'utils/logError';

export default function useCompleteSubmissionForm() {
  const { submissionData, mutate: mutateSubmittionData, reset: resetSubmissionData } = useSubmissionData();
  const { data: submittedPartiesHistoryData, mutate: mutateSubmittedPartiesHistory } = useSubmittedPartiesHistory();
  const { prompts } = usePrompts();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { name, description, generatedImages, flavour, ingredients, mainPrompt, cocktail } = { ...submissionData };
  const { textPrompt: mainTextPrompt = '', imagePrompt: mainImagePrompt = '' } = { ...mainPrompt?.attributes };
  const [activeGeneratedImage, setActiveGeneratedImage] = useState<string | undefined>(generatedImages[0]);
  const isSelectImageStage = !!generatedImages?.length;
  const noTextGenerated = !name;
  const { prompt: flavourTextPrompt = '', imagePrompt: flavourImagePrompt = '' } = { ...flavour?.attributes };
  const { prompt: cocktailTextPrompt = '', imagePrompt: cocktailImagePrompt = '' } = { ...cocktail?.attributes };
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const mounted = useRef(false);
  const abortController = useRef(new AbortController());
  const [textGenerationAborted, setTextGenerationAborted] = useState(false);

  useEffect(() => {
    if (!mounted.current) {
      generateImages();
    }

    return () => {
      mounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { validate } = useFormCustomValidations({
    validations: [
      {
        inputName: 'birthday',
        isValid: (el: HTMLInputElement) => {
          const birthday = new Date(el.value);
          const birthdayIsValid = !isNaN(birthday.getTime());
          if (birthdayIsValid) {
            const now = new Date();
            const diff = now.getTime() - birthday.getTime();
            const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
            return age >= 21 && age <= 150;
          }
          return true;
        },
        message: 'You must be 21 or older to submit',
      },
      {
        inputName: 'confirmEmail',
        isValid: (el: HTMLInputElement) => {
          const email = el.closest('form')?.querySelector<HTMLInputElement>('input[name="email"]')?.value;
          if (!email) return false;
          return email === el.value;
        },
        message: 'Confirm email must match email',
      },
    ],
  });

  const getPartyImage = async () => {
    const el = document.getElementById('partyImage')?.children?.[0] as HTMLElement | null;
    if (!el) {
      // Should never happen
      logError('Could not find element with id "partyImage"');
      throw new Error('Could not find element with id "partyImage"');
    }
    try {
      const screenShot = await takeScreenshot(el, {
        fetch: {
          requestInit: {
            mode: 'cors',
            cache: 'no-cache',
          },
        },
        drawImageInterval: 250,
      });
      return screenShot;
    } catch (err: any) {
      logError(err);
      return null;
    }
  };

  const submitParty = async (e: any) => {
    if (!activeGeneratedImage) return;

    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const partyImage = await getPartyImage();
      if (!partyImage) {
        logError('No party image');
        setLoading(false);
        setError('Something went wrong');
        return;
      }
      const form = e.target as HTMLFormElement;
      const { firstName, lastName, birthday, email, postcode, phone, state, agree, subscribe } = serialize(form) as {
        firstName: string;
        lastName: string;
        phone: string;
        birthday: string;
        email: string;
        postcode: string;
        state: string;
        agree: string;
        subscribe: string;
      };
      const formData = new FormData();
      formData.append('files.image', partyImage);
      formData.append(
        'data',
        JSON.stringify({
          name,
          description,
          ingredients: ingredients.join('\n'),
          authorFirstName: firstName,
          authorLastName: lastName,
          authorBirthday: birthday,
          authorEmail: email,
          authorPostcode: postcode,
          authorPhone: phone,
          authorState: state,
          authorSubscribed: subscribe,
          authorAgreed: agree,
          flavour: flavour?.id,
        })
      );
      const party = await createParty(formData);
      resetSubmissionData();
      mutateSubmittedPartiesHistory(
        {
          partiesId: [...submittedPartiesHistoryData.partiesId, party.data.id],
        },
        false
      );
      setLoading(false);
      return party;
    } catch (err: any) {
      logError(err);
      setLoading(false);
      setError('Something went wrong');
      return;
    }
  };

  async function generateImages() {
    setLoading(true);
    setError('');

    try {
      const [ingredient1, ingredient2, ingredient3] = ingredients;
      const randomPrompt = !!prompts?.data?.length
        ? prompts.data[Math.floor(Math.random() * prompts.data.length)]?.attributes?.prompt || ''
        : '';
      const imagePrompt = replacePromptKeys(
        { ingredient1, ingredient2, ingredient3, partyName: name },
        mainImagePrompt + ' ' + flavourImagePrompt + ' ' + cocktailImagePrompt + ' ' + randomPrompt
      );
      const data = await generateImagesWithCancellation(
        imagePrompt,
        abortController.current.signal.aborted ? undefined : abortController.current.signal
      );
      const generatedImages = data.data;

      if (!generatedImages) {
        logError('No generated images');
        setLoading(false);
        setError('Something went wrong');
        return;
      }
      mutateSubmittionData(
        // @ts-ignore
        (prev) => ({
          ...prev,
          generatedImages: [...submissionData.generatedImages, ...generatedImages],
        }),
        false
      );
      !activeGeneratedImage && setActiveGeneratedImage(generatedImages[0]);
      setLoading(false);
    } catch (err: any) {
      if (err.name === 'AbortError') {
        console.log('aborted');
        setTextGenerationAborted(true);
        return;
      }
      logError(err);
      setLoading(false);
      setError('Something went wrong');
      return;
    }
  }

  const generateText = async () => {
    setLoading(true);
    setError('');

    try {
      const [ingredient1, ingredient2, ingredient3] = ingredients;
      const prompt = replacePromptKeys(
        { ingredient1, ingredient2, ingredient3 },
        mainTextPrompt + ' ' + flavourTextPrompt + ' ' + cocktailTextPrompt
      );
      const data = await generateTextApi(prompt);
      const generatedText = data.data;
      if (!generatedText) {
        logError('No generated text');
        setLoading(false);
        setError('Something went wrong');
        return;
      }
      const {
        link: cocktailLink = '',
        name: cocktailName = '',
        recipe: cocktailRecipe = '',
      } = {
        ...cocktail?.attributes,
      };
      mutateSubmittionData(
        // @ts-ignore
        (prev) => ({
          ...prev,
          name: generatedText.name,
          description: `${replaceDescription(
            generatedText.description,
            cocktailName,
            cocktailLink
          )}\n\n**Featured Cocktail:** ${cocktailName}\n\n${cocktailRecipe}`,
        }),
        false
      );
      setLoading(false);
    } catch (err: any) {
      logError(err);
      setLoading(false);
      setError('Something went wrong');
      return;
    }
  };

  const toggleModal = () => setOpenModal(!openModal);

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = validate(e.currentTarget);
    if (!isValid) return;
    toggleModal();
    const party = await submitParty(e);
    party && navigate(`/all-ragers/${party.data.attributes.slug}`);
  };

  const setNextActiveImage = () => {
    if (!activeGeneratedImage) return;
    const index = generatedImages.indexOf(activeGeneratedImage);
    const nextIndex = index + 1;
    const nextActiveImage = generatedImages[nextIndex] || generatedImages[0];
    setActiveGeneratedImage(nextActiveImage);
  };

  const setPreviousActiveImage = () => {
    if (!activeGeneratedImage) return;
    const index = generatedImages.indexOf(activeGeneratedImage);
    const previousIndex = index - 1;
    const previousActiveImage = generatedImages[previousIndex] || generatedImages[generatedImages.length - 1];
    setActiveGeneratedImage(previousActiveImage);
  };

  const generateTextWithImages = async () => {
    abortController.current.abort();
    await generateText();
    await generateImages();
  };

  return {
    error,
    loading,
    activeGeneratedImage,
    setActiveGeneratedImage,
    setNextActiveImage,
    setPreviousActiveImage,
    isSelectImageStage,
    noTextGenerated,
    party: submissionData,
    generateImages,
    regenImagesButtonDisabled: submissionData.generatedImages.length > 8,
    generateText,
    flavourImageUrl: flavour?.attributes?.overlayImage?.data?.attributes?.url,
    openModal,
    toggleModal,
    handleFormSubmit,
    generateTextWithImages,
    isTextGenerationAborted: textGenerationAborted,
  } as const;
}
