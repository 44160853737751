import { Helmet } from 'react-helmet';
import { Seo } from 'types/strapi';

export default function SeoHead(props: { seo?: Partial<Seo> }) {
  const defaults = {
    title: 'Three Olives® Vodka',
    description: '',
  };

  return (
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{props.seo?.metaTitle || defaults.title}</title>
      <meta name="description" content={props.seo?.metaDescription || defaults.description} />
      {/* <meta property="og:url" content={ogUrl} />
      <meta property="og:type" content={props.type} />
      <meta property="og:title" content={props.seo?.metaTitle || "Default title"} />
      <meta property="og:description" content={props.seo?.metaDescription || "Default description"} />
      <meta property="og:image" content={ogImg} /> */}
    </Helmet>
  );
}
