import clsx from 'clsx';
import style from './ErrorMessage.module.css';
import Title from './Title';

export default function ErrorMessage({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <Title size="small" color="primarydark" className={clsx(style.error, className)}>
      {children}
    </Title>
  );
}
