import styles from './Footer.module.css';
import StrapiLink from './StrapiLink';
import useFooter from 'hooks/useFooter';
import Title from 'components/ui/Title';
import clsx from 'clsx';
import backgroundImageUrl from 'assets/svg/brush-cyan-editted.svg';
import { Link } from 'types/strapi';

export default function Footer({ withoutBackground = false }: { withoutBackground?: boolean }) {
  const { footer } = useFooter();
  const { links = [] } = { ...footer?.data?.attributes };

  return (
    <div className={clsx(styles.container, withoutBackground && styles.containerWithoutBackground)}>
      {!withoutBackground && <img className={styles.backgroundImage} src={backgroundImageUrl} alt="" />}
      <div className={styles.containerInner}>
        <ul className={styles.linkItems}>
          {links.map((link: Link) => (
            <li key={link.id} className={styles.linkItem}>
              <StrapiLink url={link.url} className={styles.link} openInNewTab={true}>
                <Title tag="span" size="xsmallthin" className={styles.linkText}>
                  {link.label}
                </Title>
              </StrapiLink>
            </li>
          ))}
        </ul>
        <div className={styles.copywrite}>©2023 Proximo, Jersey City, NJ. Please drink responsibly</div>
      </div>
    </div>
  );
}
