import clsx from 'clsx';
import style from './Markdown.module.css';
import ReactMarkdown from 'react-markdown';
import Button from './Button';

export default function Markdown({ children, className }: { children: string; className?: string }) {
  return (
    <ReactMarkdown
      className={clsx(style.markdown, className)}
      children={children}
      components={{
        h6: ({ node, ...props }) => <i {...props} />,
        em: ({ node, ...props }) => <sup {...props} />,
        code: ({ node, ...props }) => <span className={style.fontTitle}>{props.children}</span>,
        a: ({ node, ...props }) => (
          <Button tag="a" href={props.href || ''} variant="link" target="_blank">
            {props.children}
          </Button>
        ),
      }}
    />
  );
}
