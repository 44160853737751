import styles from './AgeGate.module.css';
import logoUrl from 'assets/svg/logo.svg';
import { useState } from 'react';
import { getAgeConfirmed } from 'utils/localStorage';

export default function AgeGate() {
  const [active, setActive] = useState(getAgeConfirmed() !== 'true');

  const onYesClick = () => {
    setActive(false);
    localStorage.setItem('ageConfirmed', 'true');
  };

  if (!active) return null;

  return (
    <div className={styles.container}>
      <div className={styles.containerInner}>
        <div className={styles.card}>
          <img src={logoUrl} className={styles.logo} alt="" />
          <div className={styles.title}>ARE YOU OF LEGAL DRINKING AGE?</div>
          <div className={styles.buttons}>
            <button onClick={onYesClick} className={styles.buttonYes}>
              YES
            </button>
            <a href="https://responsibility.org/" rel="noreferrer" target="_blank" className={styles.buttonNo}>
              NO
            </a>
          </div>
          <div className={styles.note}>
            You must be of legal drinking age to enter this website. This website uses cookies. By entering this site, I
            agree to the{' '}
            <a href="https://proximospirits.com/terms-of-service/" rel="noreferrer" target="_blank">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://proximospirits.com/privacy/" rel="noreferrer" target="_blank">
              Privacy Policy
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
}
