import SeoHead from 'components/shared/SeoHead';
import styles from './Home.module.css';
import Layout from 'components/shared/Layout';
import PasswordForm from 'components/shared/PasswordForm';

export default function Password() {
  return (
    <>
      <SeoHead seo={{ metaTitle: 'Password | Three Olives® Vodka' }} />
      <Layout>
        <div className={styles.container}>
          <div className={styles.containerInner}>
            <PasswordForm />
          </div>
        </div>
      </Layout>
    </>
  );
}
